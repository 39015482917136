import { useEffect, useState } from 'react';
import { Box } from '@mui/material';

interface ITimer {
  onExpire: () => void;
  expireInSeconds: number;
}

export default function SendCodeTimer({ onExpire, expireInSeconds }: ITimer) {
  const [remainingSeconds, setRemainingSeconds] = useState(expireInSeconds);

  useEffect(() => {
    const interval = setInterval(() => {
      if (remainingSeconds === 0) {
        onExpire();
      }

      setRemainingSeconds(remainingSeconds - 1);
    }, 1000);

    return () => clearInterval(interval);
  });

  return (
    <Box display="inline-block" fontSize="14px">
      in {remainingSeconds} sec
    </Box>
  );
}
