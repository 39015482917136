import { Button, Box, useTheme } from '@mui/material';

type FooterProps = {
  buttonLabel: TStepConfig['buttonLabel'];
  buttonDisabled: boolean;
};

const StepFooter = ({ buttonLabel, buttonDisabled = false }: FooterProps) => {
  const theme = useTheme();

  return (
    <Box
      id="onboarding-footer"
      data-testid="onboarding-footer"
      flex="1"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="end"
      width="100%"
      maxWidth="sm"
      textAlign="center"
      marginTop="12px"
    >
      <Button
        id="footer-button"
        data-testid="footer-button"
        variant="contained"
        type="submit"
        disabled={buttonDisabled}
        sx={{
          height: '48px',
          width: '90%',
          fontSize: '16px',
          fontWeight: 500,
          marginBottom: '8px',
          textTransform: 'none',
          backgroundColor: theme.branding.primaryColor,
          color: theme.branding.textPrimaryColor,
        }}
      >
        {buttonLabel || 'Next'}
      </Button>
    </Box>
  );
};

export default StepFooter;
