import { Box, Container, Dialog, IconButton, Typography } from '@mui/material';
import closeIcon from 'assets/images/close-icon.svg';
import GuideContent from './GuideContent';

type HelpProps = {
  isHelpOpen: boolean;
  onClose: () => void;
};

const HelpModal = ({ isHelpOpen, onClose }: HelpProps) => {
  return (
    <Dialog
      id="help-modal"
      data-testid="help-modal"
      fullScreen
      open={isHelpOpen}
    >
      <Container
        id="help-modal-container"
        data-testid="help-modal-container"
        maxWidth="xs"
      >
        <Box
          id="help-modal-header"
          data-testid="help-modal-header"
          display="flex"
          justifyContent="space-between"
          padding="30px 0 0 20px"
        >
          <Typography variant="body1">Onboarding Guidelines</Typography>
          <IconButton onClick={onClose} title="Close">
            <img alt="Close" src={closeIcon} />
          </IconButton>
        </Box>
        <Box
          id="help-modal-content"
          data-testid="help-modal-content"
          padding="40px 0 0 20px"
        >
          <GuideContent />
        </Box>
      </Container>
    </Dialog>
  );
};

export default HelpModal;
