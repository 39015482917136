import { createContext, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import Logger from 'util/Logger';
import AppContainer from 'onboarding/components/AppContainer';
import Onboarding from 'onboarding/Onboarding';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 0,
    },
  },
});

if (process.env.NODE_ENV === 'development') {
  const { worker } = require('./mocks/browser');
  worker.start();
}

Logger.init();
Logger.logEvent(Logger.events.AppStarted);

interface dataErrorInterface {
  type: string;
  message: string;
}

export const AppContext = createContext({
  organization: '',
  requestId: '',
  isCompleted: false,
  setIsCompleted: (isCompleted: boolean) => {},
  hasError: false,
  setHasError: (hasError: boolean) => {},
  brandStatus: 0,
  setBrandStatus: (brandStatus: number) => {},
  isLinkExpired: false,
  setIsLinkExpired: (brandStatus: boolean) => {},
  validationStatus: '',
  setValidationStatus: (validationStatus: string) => {},
  dataErrorMessage: { type: '', message: '' },
  setDataErrorMessage: (dataErrorMessage: dataErrorInterface) => {},
});

function App() {
  const [isCompleted, setIsCompleted] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [brandStatus, setBrandStatus] = useState(0);
  const [isLinkExpired, setIsLinkExpired] = useState(false);
  const [validationStatus, setValidationStatus] = useState('');
  const [dataErrorMessage, setDataErrorMessage] = useState({
    type: '',
    message: '',
  });

  const searchParams = new URLSearchParams(window.location.search);
  const organization = searchParams.get('orgAlias') || '';
  const requestId = searchParams.get('requestId') || '';

  return (
    <QueryClientProvider client={queryClient}>
      <AppContext.Provider
        value={{
          organization,
          requestId,
          isCompleted,
          setIsCompleted,
          hasError,
          setHasError,
          brandStatus,
          setBrandStatus,
          isLinkExpired,
          setIsLinkExpired,
          validationStatus,
          setValidationStatus,
          dataErrorMessage,
          setDataErrorMessage,
        }}
      >
        <AppContainer>
          <Onboarding />
        </AppContainer>
      </AppContext.Provider>
    </QueryClientProvider>
  );
}

export default App;
