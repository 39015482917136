import { useContext, useEffect } from 'react';

import { axiosPrivate } from 'api/apiClient';
import { useWizard } from 'react-use-wizard';
import { useGetToken } from 'api/api';
import { AppContext } from 'App';

/**
 * Custom hook for intercepting the api call
 *
 * @method useAxiosPrivate
 */
const useAxiosPrivate = () => {
  const { goToStep } = useWizard();
  const token = JSON.parse(localStorage.getItem('token')!);
  const getToken = useGetToken();
  const { setHasError } = useContext(AppContext);


  useEffect(() => {
    /**
     * Request intercepts if:
     * 1) there's no Authorization header
     * 2) if it's development environment
     * 3) if the token exists in the local storage
     *
     * @property requestIntercept
     */
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config) => {
        if (
          !config.headers['Authorization'] &&
          process.env.NODE_ENV === 'development' &&
          token
        ) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error),
    );

    /**
     * Response intercepts if response status code is 401
     * 1) for dev env in case there's an error:
     *  - we make a new call to getToken() endpoint
     *  - await the result
     *  - if the result is successful, we call the previous request with the new token from the getToken result
     *  - otherwise we remove the token from the local storage and go to verification step
     * 2) for prod env - we remove the token from the local storage and go to verification step
     *
     * @property requestIntercept
     */
    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error?.response?.status === 401) {
          setHasError(false);
          if (process.env.NODE_ENV === 'development') {
            const prevRequest = error?.config;
            if (!prevRequest.sent) {
              prevRequest.sent = true;
              const newAccessToken = await getToken();
              if (newAccessToken) {
                prevRequest.headers[
                  'Authorization'
                ] = `Bearer ${newAccessToken}`;
                return axiosPrivate(prevRequest);
              } else {
                localStorage.removeItem('token');
                goToStep(0);
              }
            }
          } else {
            goToStep(0);
          }
        }

        return Promise.reject(error);
      },
    );

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept);
      axiosPrivate.interceptors.response.eject(responseIntercept);
    };
  }, [goToStep, getToken, token, setHasError]);

  return axiosPrivate;
};

export default useAxiosPrivate;
