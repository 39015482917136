import { Alert, Box } from '@mui/material';
import React from 'react';

import fourthLogo from 'assets/images/fourth-logo.svg';

const InvalidURL = () => {
  return (
    <Box
      id="index-step-body"
      data-testid="index-step-body"
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        textAlign="center"
        fontSize="18px"
        fontWeight="600"
        width="240px"
        marginTop="12px"
        marginBottom="24px"
        color="#fff"
      >
        Onboarding Verification
      </Box>
      <Alert sx={{ width: '100%', marginTop: '20px' }} severity="error">
        The link has expired.
      </Alert>
      <img src={fourthLogo} className='centered-element' alt='fourth logo'/>
    </Box>
  );
};

export default InvalidURL;
