const theme = {
  branding: {
    primaryColor: '#1976D2',
    logo: '',
    textPrimaryColor: '#FFFFFF',
  },
  palette: {
    primary: { main: '#a6a6a6' },
    background: {
      default: '#F0F3F5',
    },
  },
  typography: {
    fontFamily: [
      'Inter',
      '-apple-system',
      'BlinkMacSystemFont',
      'Roboto',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
    subtitle1: {
      fontSize: '18px',
      fontWeight: 500,
      lineHeight: '24px',
    },
    subtitle2: {
      fontWeight: 700,
    },
    h6: {
      fontWeight: 600,
    },
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '14px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: '14px',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '14px',
        },
      },
    },
  },
};

export default theme;
