import { Box, Typography } from '@mui/material';

const GuideContent = () => {
  const guideLines = [
    '1. You can come back to this app anytime by simply clicking on the link within the initial welcome SMS text message we sent you.',
    "2. You'll receive an SMS with a verification code each time you wish to log into Onboarding to continue from where you left off.",
    '3. You can complete your onboarding in your own time, in multiple stages by following the above steps.',
    '4. When you are done and complete onboarding, you will have the ability to access our Engage platform to meet your colleagues!',
  ].map((lineText, i) => (
    <Typography
      variant="body2"
      pb="20px"
      pl="2px"
      sx={{ textIndent: '-14px' }}
      key={i}
    >
      {lineText}
    </Typography>
  ));

  return (
    <Box id="guide-content" data-testid="guide-content" color="#333333">
      {guideLines}
    </Box>
  );
};

export default GuideContent;
