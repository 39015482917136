import { useContext } from 'react';
import { useWizard } from 'react-use-wizard';
import { Box, Link, Typography, useTheme } from '@mui/material';
import { OnboardingRequestContext } from 'onboarding/components/AppContainer';
import FormsCompleteIcon from 'onboarding/components/svg-icons/FormsCompleteIcon';

const ReadyToSign = (sectionsStatus: TSectionsStatus) => {
  const { onboardingStepsConfig } = useContext(OnboardingRequestContext);
  const { goToStep } = useWizard();
  const theme = useTheme();
  let incompleteSteps;
  let showIncompleteSteps = false;

  if (sectionsStatus) {
    incompleteSteps = onboardingStepsConfig.steps
      .filter(
        (configStep) =>
          configStep.isForm && sectionsStatus[configStep.id] === 'False',
      )
      .map((incompleteForm, i) => (
        <Box key={i} paddingBottom="16px">
          <Link
            component="button"
            onClick={() => {
              const incompleteFormStepIndex =
                onboardingStepsConfig.steps.findIndex(
                  (step) => step.id === incompleteForm.id,
                );

              if (incompleteFormStepIndex !== -1) {
                goToStep(incompleteFormStepIndex);
              }
            }}
            sx={{ color: theme.branding.primaryColor }}
          >
            {incompleteForm.name}
          </Link>
        </Box>
      ));
    showIncompleteSteps = incompleteSteps.length !== 0;
  }

  return (
    <>
      {sectionsStatus && (
        <Box
          id="forms-complete-step-body"
          data-testid="forms-complete-step-body"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          borderRadius="4px"
          margin="0 16px 0 16px"
          width="92%"
          sx={{ backgroundColor: '#FFFFFF' }}
        >
          {showIncompleteSteps ? (
            <>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                width="100%"
                fontSize="18px"
                fontWeight="500"
                padding="54px 36px 32px 36px"
                textAlign="center"
              >
                <Typography variant="h6">You are almost ready!</Typography>
                <Box width="277px" textAlign="center" padding="32px 0 54px 0">
                  <Typography variant="body1">
                    It looks like there is missing data which is required in
                    order to enable you to continue with your onboarding. Please
                    update within the following forms:
                  </Typography>
                  <Box padding="32px 0 32px 0">{incompleteSteps}</Box>
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Box
                width="100%"
                fontSize="18px"
                fontWeight="500"
                padding="54px 36px 32px 36px"
                textAlign="center"
              >
                <Typography variant="h6">You are almost ready!</Typography>
              </Box>
              <Box width="206px" textAlign="center" paddingBottom="32px">
                <Typography variant="body1">
                  You will be asked to complete and sign your contract on the
                  next page.
                </Typography>
              </Box>
              <FormsCompleteIcon fill={theme.branding.primaryColor} />
              <Box width="277px" textAlign="center" padding="32px 0 54px 0">
                <Typography variant="body1">
                  Before doing so, please ensure all information provided so far
                  is correct. Use the Home button in the top corner to go back
                  to the dashboard and check each page. Once you have signed
                  your contract, remember to press FINISH, so your new record
                  will be created in our HR system.
                </Typography>
              </Box>
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default ReadyToSign;
