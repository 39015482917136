import { useState, useCallback, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Checkbox,
  ListItemText,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  FormHelperText,
} from '@mui/material';

const DisabilitySelector = ({
  isDisabled,
  disabilities,
  isDisabledOptions = [],
  disabilityOptions = [],
}: {
  isDisabled: string;
  disabilities: string[];
  isDisabledOptions: string[];
  disabilityOptions: string[];
}) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();
  const [selectedDisabilities, setSelectedDisabilities] =
    useState(disabilities);
  const [hasDisability, setHasDisability] = useState(disabilities.length > 0);

  useEffect(() => {
    if (!disabilities || disabilities.length === 0) setValue('Disability', []);
  }, [setValue, disabilities]);

  const onDisabilityCheck = (selectedValue: string) => () => {
    const currentIndex = selectedDisabilities.indexOf(selectedValue);
    const newSelected = [...selectedDisabilities];

    if (currentIndex === -1) {
      newSelected.push(selectedValue);
    } else {
      newSelected.splice(currentIndex, 1);
    }

    setValue('Disability', [...newSelected]);
    setSelectedDisabilities(newSelected);
  };

  const onIsDisabledChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value === isDisabledOptions[2]) {
        setHasDisability(true);
        setValue('IsDisabled', event.target.value);
        return;
      }

      setValue('IsDisabled', event.target.value);
      setHasDisability(false);
      setSelectedDisabilities([]);
      setValue('Disability', []);
    },
    [isDisabledOptions, setValue],
  );

  return (
    <FormControl sx={{ width: '100%' }}>
      <Controller
        name="IsDisabled"
        control={control}
        rules={{ required: 'required' }}
        defaultValue={isDisabled}
        render={() => (
          <>
            <FormLabel
              sx={{
                fontSize: '14px',
                color: (theme) => {
                  if (!!errors['IsDisabled']) return theme.palette.error.main;
                },
              }}
            >
              Do you consider yourself to have a disability? *
            </FormLabel>
            <FormHelperText error={!!errors['IsDisabled']}>
              {errors['IsDisabled']?.message as string}
            </FormHelperText>
            <RadioGroup onChange={onIsDisabledChange} defaultValue={isDisabled}>
              {isDisabledOptions.map((option, index) => (
                <FormControlLabel
                  key={`radio${index + 1}`}
                  value={option}
                  control={<Radio />}
                  label={option}
                />
              ))}
            </RadioGroup>
          </>
        )}
      />
      {hasDisability && (
        <Controller
          name="Disability"
          control={control}
          defaultValue={disabilities || []}
          rules={{ required: 'you must select at least one option' }}
          render={() => (
            <>
              <FormHelperText error={!!errors['Disability']}>
                {errors['Disability']?.message as string}
              </FormHelperText>
              <List
                sx={{
                  width: '100%',
                  maxWidth: 360,
                  bgcolor: 'background.paper',
                }}
              >
                {disabilityOptions.map((disability: string, index: number) => {
                  const labelId = `checkbox-list-label-${index}`;

                  return (
                    <ListItem key={`disability${index + 1}`} disablePadding>
                      <ListItemButton
                        role={undefined}
                        onClick={onDisabilityCheck(disability)}
                        dense
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={
                              selectedDisabilities.indexOf(disability) !== -1
                            }
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-label': labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={disability} />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </>
          )}
        />
      )}
    </FormControl>
  );
};

export default DisabilitySelector;
