import { useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  CheckboxProps,
  Typography,
  Box,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import dayjs from 'dayjs';
import DateInput from 'onboarding/components/form-controls/DateInput';

type FieldProps = {
  index: number;
  employedFrom: string | undefined;
  employedTo: string | undefined;
  mandatorySign: string;
} & CheckboxProps;

const EmployedFromToSelector = ({
  index: i,
  employedFrom,
  employedTo,
  mandatorySign,
  ...otherProps
}: FieldProps) => {
  const [checked, setChecked] = useState(!employedTo);
  const { control, setValue, getValues } = useFormContext();

  return (
    <Controller
      name={`EmployedTo${i}`}
      control={control}
      // rules={{ ...rules }}
      defaultValue={employedTo}
      render={({ field }) => (
        <>
          <FormControlLabel
            id={`employed-to${i}`}
            data-testid={`employed-to${i}`}
            label="Current employment"
            sx={{ marginLeft: '2px' }}
            control={
              <Checkbox
                edge="start"
                checked={checked}
                onChange={(event) => {
                  setChecked(event.target.checked);
                  if (event.target.checked)
                    setValue(`EmployedTo${i}`, undefined);
                }}
                tabIndex={-1}
                disableRipple
              />
            }
          />
          <Box
            display="flex"
            flexDirection="row"
            sx={{ marginTop: '10px', gap: '12px' }}
          >
            <DateInput
              id={`employed-from${i}`}
              data-testid={`employed-from${i}`}
              name={`Employedfrom${i}`}
              label={`Employed From${mandatorySign}`}
              initialValue={employedFrom}
              maxDate={
                getValues(`EmployedTo${i}`)
                  ? dayjs(getValues(`EmployedTo${i}`)).subtract(1, 'day')
                  : dayjs().subtract(1, 'day')
              }
              errorMessage={
                getValues(`EmployedTo${i}`)
                  ? 'Date must be before Employed To'
                  : 'You cannot select a date in the future.'
              }
              sx={{ width: '48%' }}
            />
            {!checked ? (
              <DateInput
                id={`employed-to${i}`}
                data-testid={`employed-to${i}`}
                name={`EmployedTo${i}`}
                label={`Employed To${mandatorySign}`}
                initialValue={employedTo}
                rules={!checked && { required: 'required' }}
                minDate={
                  getValues(`Employedfrom${i}`)
                    ? dayjs(getValues(`Employedfrom${i}`)).add(1, 'day')
                    : undefined
                }
                errorMessage="Date must be after Employed From."
                sx={{ width: '48%' }}
              />
            ) : (
              <Typography
                component="div"
                variant="body2"
                sx={{ marginTop: '16px' }}
              >
                to present
              </Typography>
            )}
          </Box>
        </>
      )}
    />
  );
};

export default EmployedFromToSelector;
