import { forwardRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TextField, TextFieldProps } from '@mui/material';
import { PatternFormat, PatternFormatProps } from 'react-number-format';

type FieldProps = {
  name: string;
  rules?: {};
  defaultValue?: any;
} & TextFieldProps;

type CustomProps = {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
};

const PatternFormatCustom = forwardRef<PatternFormatProps, CustomProps>(
  (props, ref) => {
    const { onChange, ...other } = props;

    return (
      <PatternFormat
        {...other}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        getInputRef={ref}
        valueIsNumericString
        format="##-##-##"
      />
    );
  },
);

export default function SortCodeInput({
  name,
  rules,
  defaultValue,
  ...otherProps
}: FieldProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{ ...rules }}
      defaultValue={defaultValue || ''}
      render={({ field }) => (
        <TextField
          error={!!errors[name]}
          helperText={errors[name]?.message as string}
          {...otherProps}
          InputProps={{
            inputComponent: PatternFormatCustom as any,
          }}
          inputProps={{
            ...field,
          }}
        />
      )}
    />
  );
}
