import { Box, Typography } from '@mui/material';

const RequestPausedStep = () => {
  return (
    <Box
      id="create-record-step-body"
      data-testid="create-record-step-body"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      borderRadius="4px"
      margin="32px 16px 0 16px"
      width="92%"
      sx={{ backgroundColor: '#FFFFFF' }}
    >
      <Box
        width="100%"
        fontSize="18px"
        fontWeight="500"
        padding="54px 36px 16px 36px"
        textAlign="center"
      >
        <Typography variant="h6">Your onboarding request is paused</Typography>
      </Box>
    </Box>
  );
};

export default RequestPausedStep;
