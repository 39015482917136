import { useEffect, useState } from 'react';
import { useWizard } from 'react-use-wizard';
import { Box, LinearProgress, Typography } from '@mui/material';
import { useESignCompleted } from 'api/api';

const CreateEmploymentRecord = () => {
  const { isSuccess, data } = useESignCompleted();
  const [infoMessageIndex, setInfoMessageIndex] = useState(0);
  const { goToStep, activeStep } = useWizard();
  const infoMessages = [
    'Creating your employee record...',
    'Provisioning your profile...',
    'Uploading your documents...',
    'Finishing up...',
  ];

  useEffect(() => {
    if (isSuccess && data.IsSuccessful) {
      setTimeout(() => {
        if (infoMessageIndex < infoMessages.length - 1) {
          setInfoMessageIndex(infoMessageIndex + 1);
        } else {
          goToStep(activeStep + 1);
        }
      }, 2000);
    }
  }, [
    activeStep,
    goToStep,
    infoMessageIndex,
    infoMessages.length,
    isSuccess,
    data,
  ]);

  return (
    <Box
      id="create-record-step-body"
      data-testid="create-record-step-body"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      borderRadius="4px"
      margin="32px 16px 0 16px"
      width="92%"
      sx={{ backgroundColor: '#FFFFFF' }}
    >
      <Box
        width="100%"
        fontSize="18px"
        fontWeight="500"
        padding="54px 36px 36px 36px"
        textAlign="center"
      >
        <Typography variant="h6">Creating your Employment Record</Typography>
      </Box>
      <Box width="237px" textAlign="center" padding="36px 0 36px 0">
        <Typography variant="body1">
          You're all set, we are now creating your employee record. This may
          take a few moments.
        </Typography>
      </Box>
      <Box width="254px" textAlign="center" padding="36px 0 36px 0">
        <LinearProgress sx={{ height: '10px', borderRadius: '10px' }} />
      </Box>
      <Box width="277px" textAlign="center" padding="36px 0 136px 0">
        <Typography variant="body1">
          {infoMessages[infoMessageIndex]}
        </Typography>
      </Box>
    </Box>
  );
};

export default CreateEmploymentRecord;
