import { Box, Skeleton, Typography } from '@mui/material';
import FormSectionItem from 'onboarding/components/form-controls/FormSectionItem';

type FormSectionProps = React.PropsWithChildren<{
  name: string;
  isReady?: boolean;
  hasHeader?: boolean;
}>;

const FormSection = ({
  name,
  isReady = true,
  hasHeader = false,
  children,
}: FormSectionProps) => {
  const sectionName = name.toLowerCase().replace(' ', '-');
  const sectionId = `form-section_${sectionName}`;
  const sectionHeaderId = `form-section_header_${sectionName}`;
  const notReadyItem = <Skeleton variant="rounded" width="100%" height={50} />;
  const divider = <Box borderTop="1px solid #CCCCCC" width="100%" />;

  const sectionItems = (
    Array.isArray(children) ? (children as Array<React.ReactNode>) : [children]
  ).map((item, i) => (
    <FormSectionItem key={i}>{isReady ? item : notReadyItem}</FormSectionItem>
  ));

  return (
    <Box
      id={sectionId}
      data-testid={sectionId}
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="100vw"
      maxWidth="400px"
      sx={{ backgroundColor: '#FFFFFF' }}
    >
      {hasHeader && (
        <Box id={sectionHeaderId} data-testid={sectionHeaderId} width="100%">
          <Box padding="16px 20px 8px 20px">
            <Typography variant="subtitle2">{name}</Typography>
          </Box>
          {divider}
        </Box>
      )}
      {sectionItems}
    </Box>
  );
};

export default FormSection;
