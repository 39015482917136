import { createContext, useMemo } from 'react';
import * as ReactDOMServer from 'react-dom/server';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Container, Box } from '@mui/material';
import OnboardingConfig from 'onboarding/onboardingConfig';
import { useBrand } from 'api/api';
import onboardingTheme from 'theme';
import EllipticBackground from 'onboarding/components/EllipticBackground';
import SplashModal from 'onboarding/components/SplashModal';
import fourthLogo from 'assets/images/fourth-logo.svg';
import getContrastRatio from 'util/contrastRatio';

declare module '@mui/material/styles' {
  interface Theme {
    branding: {
      primaryColor: string;
      logo: string;
      textPrimaryColor: string;
    };
  }
  interface ThemeOptions {
    branding?: {
      primaryColor?: string;
      logo?: string;
      textPrimaryColor?: string;
    };
  }
}

export const OnboardingRequestContext = createContext(
  {} as TOnboardingRequestContext,
);

const AppContainer = ({ children }: { children: React.ReactNode }) => {
  const { data: brandData, isLoading, isError } = useBrand();
  const {
    PrimaryColour: primaryColor,
    Logo: logoURL,
    BrandName,
    BothEmergencyContactsMandatory,
    ReferencesMandatory,
    IsDiversityandInclusionRequired,
    IsRequestPaused,
  } = brandData?.[0] || {};

  const onboardingStepsConfig = useMemo(() => {
    let onboardingSteps: TOnboardingConfig = { ...OnboardingConfig };

    if (ReferencesMandatory !== undefined && ReferencesMandatory === 0) {
      onboardingSteps.steps = onboardingSteps.steps.filter(
        (step) => step.id !== 'References',
      );
    }

    if (
      IsDiversityandInclusionRequired !== undefined &&
      !IsDiversityandInclusionRequired
    ) {
      onboardingSteps.steps = onboardingSteps.steps.filter(
        (step) => step.id !== 'SensitiveInformation',
      );
    }

    return onboardingSteps;
  }, [ReferencesMandatory, IsDiversityandInclusionRequired]);

  if (!isError && primaryColor) {
    onboardingTheme.branding.primaryColor = `${primaryColor}`;

    const contrastRatio = getContrastRatio(
      primaryColor.slice(1),
      onboardingTheme.branding.textPrimaryColor.slice(1),
    );
    if (contrastRatio < 4.5)
      onboardingTheme.branding.textPrimaryColor = '#000000';
  }

  onboardingTheme.branding.logo = !isError && logoURL ? logoURL : fourthLogo;

  const theme = createTheme(onboardingTheme);
  const bgrSvgString = encodeURIComponent(
    ReactDOMServer.renderToStaticMarkup(
      <EllipticBackground
        primaryColor={onboardingTheme.branding.primaryColor}
      />,
    ),
  );

  return (
    <OnboardingRequestContext.Provider
      value={{
        brandName: BrandName,
        emergencyContactsMandatory: BothEmergencyContactsMandatory,
        referencesSectionsMandatory: ReferencesMandatory,
        sensitiveInfoStepVisible: IsDiversityandInclusionRequired,
        onboardingStepsConfig,
        isRequestPaused: IsRequestPaused,
      }}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container
          id="app-container"
          data-testid="app-container"
          disableGutters
          maxWidth="xs"
        >
          {isLoading ? (
            <SplashModal />
          ) : (
            <Box
              id="app-content"
              data-testid="app-content"
              display="flex"
              justifyContent="center"
              minHeight="100vh"
              sx={{
                backgroundImage: `url("data:image/svg+xml,${bgrSvgString}")`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '444px 200px',
                backgroundPositionX: 'center',
              }}
            >
              {children}
            </Box>
          )}
        </Container>
      </ThemeProvider>
    </OnboardingRequestContext.Provider>
  );
};

export default AppContainer;
