import { useContext } from 'react';
import { useGetSection } from 'api/api';
import { OnboardingRequestContext } from 'onboarding/components/AppContainer';
import Input from 'onboarding/components/form-controls/Input';
import FormSection from 'onboarding/components/form-controls/FormSection';
import EmployedFromToSelector from 'onboarding/components/form-controls/EmployedFromToSelector';

const ReferencesStep = () => {
  // 0 - Not mandatory
  // 1 - only first mandatory
  // 2 - both are mandatory
  const { referencesSectionsMandatory } = useContext(OnboardingRequestContext);
  const { data, isLoading, isError } = useGetSection('References');
  const [references] = data || [{}];

  const buildSection = (reference: TReference, i = 1) => {
    const isSecondReferenceOptional =
      i === 2 && referencesSectionsMandatory === 1;
    const mandatorySign = isSecondReferenceOptional ? '' : ' *';

    return (
      <FormSection
        key={i}
        name={`Referee ${i}`}
        isReady={!isLoading && !isError}
        hasHeader
      >
        <Input
          id={`name-of-referee${i}`}
          data-testid={`name-of-referee${i}`}
          name={`NameOfReferee${i}`}
          label={`Name of Referee${mandatorySign}`}
          defaultValue={reference.NameOfReferee}
          fullWidth
          rules={{
            pattern: {
              value: /^[a-zA-Z]([a-zA-Z-'\s]*[a-zA-Z'\s])?$/,
              message: 'invalid name',
            },
          }}
        />
        <Input
          id={`job-title${i}`}
          data-testid={`job-title${i}`}
          name={`JobTitle${i}`}
          label={`Job Title${mandatorySign}`}
          defaultValue={reference.JobTitle}
          fullWidth
        />
        <Input
          id={`company-name${i}`}
          data-testid={`company-name${i}`}
          name={`CompanyName${i}`}
          label={`Company Name${mandatorySign}`}
          defaultValue={reference.CompanyName}
          fullWidth
        />
        <Input
          id={`address${i}`}
          data-testid={`address${i}`}
          name={`Address${i}`}
          label={`Address${mandatorySign}`}
          defaultValue={reference.Address}
          fullWidth
        />
        <Input
          id={`postcode${i}`}
          data-testid={`postcode${i}`}
          name={`Postcode${i}`}
          label={`Postcode${mandatorySign}`}
          defaultValue={reference.Postcode}
          fullWidth
          rules={{
            pattern: {
              value:
                /^[a-zA-Z]{1,2}([0-9]{1,2}|[0-9][a-zA-Z])\s*[0-9][a-zA-Z]{2}$/,
              message: 'invalid postcode',
            },
          }}
        />
        <Input
          id={`email${i}`}
          data-testid={`email${i}`}
          name={`Email${i}`}
          label={`Email${mandatorySign}`}
          defaultValue={reference.Email}
          fullWidth
          rules={{
            pattern: {
              value:
                /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
              message: 'invalid email',
            },
          }}
        />
        <Input
          id={`phone-number${i}`}
          data-testid={`phone-number${i}`}
          name={`PhoneNumber${i}`}
          label={`Phone Number${mandatorySign}`}
          type="tel"
          defaultValue={reference.PhoneNumber}
          fullWidth
          rules={{
            pattern: {
              value: /^[0-9]+$/,
              message: 'invalid phone number',
            },
          }}
        />
        <EmployedFromToSelector
          index={i}
          employedFrom={reference.Employedfrom}
          employedTo={reference.EmployedTo}
          mandatorySign={mandatorySign}
        />
      </FormSection>
    );
  };

  const formSections = data
    ? references.map((reference: TReference, index: number) =>
        buildSection(reference, index + 1),
      )
    : buildSection({
        NameOfReferee: undefined,
        JobTitle: undefined,
        CompanyName: undefined,
        Address: undefined,
        Email: undefined,
        PhoneNumber: undefined,
        Employedfrom: undefined,
        EmployedTo: undefined,
      });

  return <>{formSections}</>;
};

export default ReferencesStep;
