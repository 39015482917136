import { useContext, useEffect } from 'react';
import { Box, Skeleton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import { OnboardingRequestContext } from 'onboarding/components/AppContainer';
import { useGetSection } from 'api/api';
import GuideContent from 'onboarding/components/GuideContent';
import { useWizard } from 'react-use-wizard';
import { AppContext } from 'App';

const WelcomeStep = () => {
  const theme = useTheme();
  const {
    data: sectionData,
    isLoading,
    isError,
  } = useGetSection('WelcomeData');
  const [welcomeData] = sectionData || [];
  const { brandName, onboardingStepsConfig } = useContext(
    OnboardingRequestContext,
  );
  const { validationStatus } = useContext(AppContext);

  const { goToStep } = useWizard();

  useEffect(() => {
    if (validationStatus) {
      if (
        validationStatus === 'mutableError' ||
        validationStatus === 'immutableError'
      ) {
        goToStep(
          onboardingStepsConfig.findStepIndexById(
            'OnboardingCompleted',
            onboardingStepsConfig?.steps,
          ),
        );
      }

      if (validationStatus === 'errorSendingDataToPS') {
        goToStep(
          onboardingStepsConfig.findStepIndexById(
            'CreateEmploymentRecord',
            onboardingStepsConfig?.steps,
          ),
        );
      }
    }
  }, [goToStep, onboardingStepsConfig, validationStatus]);

  return (
    <Box
      id="welcome-step-body"
      data-testid="welcome-step-body"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="95%"
      borderRadius="4px"
      margin="0 16px 0 16px"
      sx={{ backgroundColor: '#FFFFFF' }}
    >
      <Box
        width="100%"
        fontSize="18px"
        fontWeight="500"
        padding="36px 36px 16px 36px"
        textAlign="center"
      >
        {isLoading || isError ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="100%"
          >
            <Skeleton variant="text" sx={{ fontSize: '18px', width: '80%' }} />
            <Skeleton variant="text" sx={{ fontSize: '18px', width: '80%' }} />
          </Box>
        ) : (
          <Typography variant="subtitle1">
            Welcome to {brandName} Onboarding, {welcomeData?.FirstName}!
          </Typography>
        )}
      </Box>
      <img
        id="brand-logo"
        data-testid="brand-logo"
        src={theme.branding.logo}
        alt="Brand logo"
        width="50%"
      />
      <Box
        width="100%"
        fontSize="16px"
        fontWeight="400"
        textAlign="center"
        lineHeight="20px"
        padding="16px 36px 24px 36px"
      >
        {isLoading || isError ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="100%"
          >
            <Skeleton variant="text" sx={{ fontSize: '16px', width: '80%' }} />
            <Skeleton variant="text" sx={{ fontSize: '16px', width: '80%' }} />
            <Skeleton variant="text" sx={{ fontSize: '16px', width: '80%' }} />
            <Skeleton variant="text" sx={{ fontSize: '16px', width: '80%' }} />
          </Box>
        ) : (
          <Typography variant="body1">
            We are excited to have you join our team as a{' '}
            {welcomeData?.JobTitle} in {welcomeData?.Location} on{' '}
            {dayjs(welcomeData?.StartDate).format('DD/MM/YYYY')}!
          </Typography>
        )}
      </Box>
      <Box borderTop="1px solid #CCCCCC" margin="0 0 24px 0" width="80%"></Box>
      <Box
        id="help-modal-content"
        data-testid="help-modal-content"
        padding="10px 40px 0 50px"
      >
        {isLoading || isError ? (
          <Skeleton
            variant="rounded"
            width={270}
            height={150}
            sx={{ marginBottom: '20px' }}
          />
        ) : (
          <GuideContent />
        )}
      </Box>
    </Box>
  );
};

export default WelcomeStep;
