import { useContext } from 'react';
import { useGetSection } from 'api/api';
import { OnboardingRequestContext } from 'onboarding/components/AppContainer';
import Input from 'onboarding/components/form-controls/Input';
import FormSection from 'onboarding/components/form-controls/FormSection';

const EmergencyContactsStep = () => {
  const { emergencyContactsMandatory } = useContext(OnboardingRequestContext);
  const { data, isLoading, isError } = useGetSection('EmergencyContacts');
  const [emergencyContacts] = data || [{}];

  const buildSection = (contact: TEmergencyContact, i = 1) => {
    const isSecondContactOptional = i === 2 && !emergencyContactsMandatory;
    const mandatorySign = isSecondContactOptional ? '' : ' *';

    return (
      <FormSection
        key={i}
        name={`Emergency Contact ${i}`}
        isReady={!isLoading && !isError}
        hasHeader
      >
        <Input
          id={`title${i}`}
          data-testid={`title${i}`}
          name={`Title${i}`}
          label={`Title${mandatorySign}`}
          defaultValue={contact.Title}
          fullWidth
          rules={{
            pattern: {
              value: /^[a-zA-Z]([a-zA-Z-'./\s]*[a-zA-Z-'./\s])?$/,
              message: 'invalid title',
            },
          }}
        />
        <Input
          id={`first-name${i}`}
          data-testid={`first-name${i}`}
          name={`FirstName${i}`}
          label={`First Name${mandatorySign}`}
          defaultValue={contact.FirstName}
          fullWidth
          rules={{
            pattern: {
              value: /^[a-zA-Z]([a-zA-Z-'\s]{0,48}[a-zA-Z'])?$/,
              message: 'invalid first name',
            },
          }}
        />
        <Input
          id={`surname${i}`}
          data-testid={`surname${i}`}
          name={`SurName${i}`}
          label={`Surname${mandatorySign}`}
          defaultValue={contact.SurName}
          fullWidth
          rules={{
            pattern: {
              value: /^[a-zA-Z]([a-zA-Z-'\s]{0,48}[a-zA-Z'])?$/,
              message: 'invalid surname',
            },
          }}
        />
        <Input
          id={`email${i}`}
          data-testid={`email${i}`}
          name={`Email${i}`}
          label={`Email${mandatorySign}`}
          defaultValue={contact.Email}
          fullWidth
          rules={{
            pattern: {
              value:
                /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
              message: 'invalid email',
            },
          }}
        />
        <Input
          id={`mobile-number${i}`}
          data-testid={`mobile-number${i}`}
          name={`MobilePhoneNumber${i}`}
          label={`Mobile Number${mandatorySign}`}
          type="tel"
          defaultValue={contact.MobilePhoneNumber}
          fullWidth
          rules={{
            pattern: {
              value: /^[0-9]{4,}$/,
              message: 'invalid mobile number',
            },
          }}
        />
        <Input
          id={`phone-number${i}`}
          data-testid={`phone-number${i}`}
          name={`HomePhoneNumber${i}`}
          label={'Phone Number'}
          type="tel"
          defaultValue={contact.HomePhoneNumber}
          fullWidth
          rules={{
            pattern: {
              value: /^[0-9]{4,}$/,
              message: 'invalid phone number',
            },
          }}
        />
        <Input
          id={`relationship${i}`}
          data-testid={`relationship${i}`}
          name={`Relationship${i}`}
          label={`Relationship to you${mandatorySign}`}
          defaultValue={contact.Relationship}
          fullWidth
          rules={{
            pattern: {
              value: /^[a-zA-Z][a-zA-Z-\s/]{1,48}[a-zA-Z]$/,
              message: 'invalid relationship name',
            },
          }}
        />
      </FormSection>
    );
  };

  const formSections = data
    ? emergencyContacts.map((contact: TEmergencyContact, index: number) =>
        buildSection(contact, index + 1),
      )
    : buildSection({
        Title: undefined,
        FirstName: undefined,
        SurName: undefined,
        Email: undefined,
        HomePhoneNumber: undefined,
        MobilePhoneNumber: undefined,
        Relationship: undefined,
      });

  return <>{formSections}</>;
};

export default EmergencyContactsStep;
