import { useGetSection } from 'api/api';
import FormSection from 'onboarding/components/form-controls/FormSection';
import Input from 'onboarding/components/form-controls/Input';
import SortCodeInput from 'onboarding/components/form-controls/SortCodeInput';

const BankDetailsStep = () => {
  const { data, isLoading, isError } = useGetSection('BankAccount');
  const [bankDetails] = data || [{}, {}];

  return (
    <FormSection name="Bank Details" isReady={!isLoading && !isError}>
      <Input
        id="bank-name"
        data-testid="bank-name"
        name="BankName"
        label="Bank/Building Society Name *"
        defaultValue={bankDetails.BankName}
        fullWidth
        rules={{
          pattern: {
            value: /^(?!\s)[a-zA-Z0-9\s]{1,100}(?<!\s)$/,
            message: 'invalid bank name',
          },
        }}
      />
      <Input
        id="account-name"
        data-testid="account-name"
        name="AccountName"
        label="Account Name *"
        defaultValue={bankDetails.AccountName}
        fullWidth
        rules={{
          pattern: {
            value: /^[a-zA-Z0-9][a-zA-Z0-9\s-]{0,98}[a-zA-Z0-9]$/,
            message: 'invalid account name',
          },
        }}
      />
      <SortCodeInput
        id="sort-code"
        data-testid="sort-code"
        name="SortCode"
        label="Sort Code *"
        defaultValue={bankDetails.SortCode}
        fullWidth
        rules={{
          pattern: {
            value: /([0-9]{2})-?([0-9]{2})-?([0-9]{2})/,
            message: 'invalid sort code',
          },
        }}
      />
      <Input
        id="account-number"
        data-testid="account-number"
        name="AccountNumber"
        label="Account Number *"
        defaultValue={bankDetails.AccountNumber}
        fullWidth
        rules={{
          pattern: {
            value: /^(?!(?:0{8}|01234567|12345678))(\d){8}$/,
            message: 'invalid account number',
          },
        }}
      />
      <Input
        id="bsoc-roll-number"
        data-testid="bsoc-roll-number"
        name="BSocRollNumber"
        label="BSoc Roll Number"
        defaultValue={bankDetails.BSocRollNumber}
        fullWidth
        rules={{
          pattern: {
            value: /^[a-zA-Z0-9][a-zA-Z0-9-\/. ]{0,17}$/,
            message: 'invalid BSoc roll number',
          },
        }}
      />
    </FormSection>
  );
};

export default BankDetailsStep;
