import { PropsWithChildren } from 'react';
import { Box } from '@mui/material';

type FormSectionItemProps = PropsWithChildren<{
  itemKey?: number;
}>;

const FormSectionItem = ({ itemKey, children }: FormSectionItemProps) => (
  <Box width="100%" key={itemKey}>
    <Box width="100%" padding="16px 25px 8px 25px">
      {children}
    </Box>
  </Box>
);

export default FormSectionItem;
