import { Controller, useFormContext } from 'react-hook-form';
import {
  Checkbox,
  ListItemText,
  Select as MUISelect,
  SelectProps,
  FormControl,
  InputLabel,
  FormHelperText,
  MenuItem,
} from '@mui/material';

type FieldProps = {
  name: string;
  rules?: {};
  defaultValue?: any;
  label: string;
  options: Array<string>;
} & SelectProps;

export default function Select({
  name,
  rules,
  label,
  defaultValue,
  options,
  ...otherProps
}: FieldProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const getOptionItems = (values: Array<string>) =>
    options.map((option: string, index) => (
      <MenuItem
        key={`${option}${index + 1}`}
        value={option}
        sx={{ fontSize: '14px' }}
      >
        {otherProps.multiple ? (
          <>
            <Checkbox checked={values.includes(option)} />
            <ListItemText primary={option} sx={{ fontSize: '14px' }} />
          </>
        ) : (
          option
        )}
      </MenuItem>
    ));

  return (
    <Controller
      name={name}
      control={control}
      rules={{ ...rules }}
      defaultValue={defaultValue || (otherProps.multiple ? [] : '')}
      render={({ field }) => (
        <FormControl sx={{ width: '100%' }}>
          <InputLabel
            id={name}
            error={!!errors[name]}
            sx={{ fontSize: '14px' }}
          >
            {label}
          </InputLabel>
          <MUISelect
            label={label}
            labelId={name}
            {...field}
            {...otherProps}
            error={!!errors[name]}
            renderValue={(selected) =>
              Array.isArray(selected) ? selected.join(', ') : selected
            }
            fullWidth
            sx={{ fontSize: '14px' }}
          >
            {getOptionItems(field.value)}
          </MUISelect>
          <FormHelperText error={!!errors[name]}>
            {errors[name]?.message as string}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
}
