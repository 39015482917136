import dayjs from 'dayjs';
import { useGetSection } from 'api/api';
import Input from 'onboarding/components/form-controls/Input';
import Select from 'onboarding/components/form-controls/Select';
import DateInput from 'onboarding/components/form-controls/DateInput';
import FormSection from 'onboarding/components/form-controls/FormSection';
import ReadOnlyInput from 'onboarding/components/form-controls/ReadOnlyInput';

export default function PersonalDetailsStep() {
  const { data, isLoading, isError } = useGetSection('PersonalInformation');
  const [personalDetails, selectsOptions] = data || [{}, {}];

  const getOptions = (name: string): [] => {
    if (data && selectsOptions[name] && Array.isArray(selectsOptions[name])) {
      return selectsOptions[name];
    }

    return [];
  };

  return (
    <>
      <FormSection
        name="Job Details"
        isReady={!isLoading && !isError}
        hasHeader
      >
        <ReadOnlyInput
          id="job-title"
          data-testid="job-title"
          label="Job Title"
          value={personalDetails.JobTitle}
        />
        <ReadOnlyInput
          id="location"
          data-testid="location"
          label="Location"
          value={personalDetails.Location}
        />
        <ReadOnlyInput
          id="start-date"
          data-testid="start-date"
          label="Employment Start Date"
          value={
            personalDetails.StartDate
              ? dayjs(personalDetails.StartDate).format('DD/MM/YYYY')
              : ''
          }
        />
      </FormSection>
      <FormSection
        name="Personal Details"
        isReady={!isLoading && !isError}
        hasHeader
      >
        <Select
          id="title"
          data-testid="title"
          name="Title"
          label="Title *"
          defaultValue={personalDetails.Title}
          options={getOptions('Title')}
        />
        <ReadOnlyInput
          id="first-name"
          data-testid="first-name"
          label="First Name"
          value={personalDetails.FirstName}
        />
        <Input
          id="middle-name"
          data-testid="middle-name"
          name="MiddleName"
          label="Middle Name"
          defaultValue={personalDetails.MiddleName}
          rules={{
            pattern: {
              value: /^[a-zA-Z]([a-zA-Z-'\s]*[a-zA-Z'\s])?$/,
              message: 'invalid middle name',
            },
          }}
          fullWidth
        />
        <ReadOnlyInput
          id="surname"
          data-testid="surname"
          label="Surname"
          value={personalDetails.SurName}
        />
        <Input
          id="preferred-name"
          data-testid="preferred-name"
          name="PreferredName"
          label="Preferred Name"
          defaultValue={personalDetails.PreferredName}
          rules={{
            pattern: {
              value: /^[a-zA-Z0-9]([a-zA-Z0-9-'\s]*[a-zA-Z0-9-'\s])?$/,
              message: 'invalid preferred name',
            },
          }}
          fullWidth
        />
        <DateInput
          id="dod"
          data-testid="dob"
          name="DoB"
          label="Date of Birth *"
          initialValue={personalDetails.DoB}
          maxDate={dayjs().subtract(16, 'year')}
          errorMessage="You must be at least 16 years of age."
          fullWidth
        />
        <Select
          id="gender"
          data-testid="gender"
          name="Gender"
          label="Gender *"
          defaultValue={personalDetails.Gender}
          options={getOptions('Gender')}
        />
      </FormSection>
      <FormSection
        name="Contact Details"
        isReady={!isLoading && !isError}
        hasHeader
      >
        <ReadOnlyInput
          id="email"
          data-testid="email"
          label="Email"
          value={personalDetails.Email}
        />
        <ReadOnlyInput
          id="mobile"
          data-testid="mobile"
          label="Mobile Phone Number *"
          value={personalDetails.MobileNumber}
        />
        <Input
          id="home-phone"
          data-testid="home-phone"
          name="HomePhoneNumber"
          label="Home Phone Number"
          type="tel"
          defaultValue={personalDetails.HomePhoneNumber}
          rules={{
            pattern: {
              value: /^[0-9]{4,}$/,
              message: 'invalid phone number',
            },
          }}
          fullWidth
        />
      </FormSection>
      <FormSection name="Address" isReady={!isLoading && !isError} hasHeader>
        <Input
          id="house"
          data-testid="house"
          name="Address1"
          label="House Name/Number and Street *"
          defaultValue={personalDetails.Address1}
          rules={{
            pattern: {
              value:
                /^(?![ ,\u0027\u2019])(?!.*[ ,\u0027\u2019]$)[A-Za-z0-9, \u0027\u2019]{4,60}$/,
              message: 'invalid house name/number',
            },
          }}
          fullWidth
        />
        <Input
          id="area"
          data-testid="area"
          name="Address2"
          label="Area"
          defaultValue={personalDetails.Address2}
          rules={{
            pattern: {
              value: /^(?!\s)(?!.*\s$)[A-Za-z0-9 ]{4,35}$/,
              message: 'invalid area name',
            },
          }}
          fullWidth
        />
        <Input
          id="city"
          data-testid="city"
          name="Town"
          label="City/Town *"
          defaultValue={personalDetails.Town}
          rules={{
            pattern: {
              value: /^[-a-zA-Z0-9]([-a-zA-Z0-9-\s]*[-a-zA-Z0-9]){2,}$/,
              message: 'invalid city/town name',
            },
          }}
          fullWidth
        />
        <Input
          id="county"
          data-testid="county"
          name="County"
          label="County"
          defaultValue={personalDetails.County}
          rules={{
            pattern: {
              value: /^[-a-zA-Z]([a-zA-Z-\s]*[a-zA-Z-]){2,}$/,
              message: 'invalid county name',
            },
          }}
          fullWidth
        />
        <Input
          id="postcode"
          data-testid="postcode"
          name="Postcode"
          label="Postcode *"
          defaultValue={personalDetails.Postcode}
          rules={{
            pattern: {
              value:
                /^[a-zA-Z]{1,2}([0-9]{1,2}|[0-9][a-zA-Z])\s*[0-9][a-zA-Z]{2}$/,
              message: 'invalid postcode',
            },
          }}
          fullWidth
        />
      </FormSection>
      <FormSection
        name="National Insurance"
        isReady={!isLoading && !isError}
        hasHeader
      >
        <Input
          id="nin"
          data-testid="nin"
          name="NiNumber"
          label="National Insurance Number"
          defaultValue={personalDetails.NiNumber}
          rules={{
            pattern: {
              value:
                /^(?!BG)(?!GB)(?!NK)(?!KN)(?!TN)(?!NT)(?!ZZ)^[A-CEGHJ-PR-TW-Z]{1}[A-CEGHJ-NPR-TW-Z]{1}[0-9]{6}[A-D]{0,1}$/,
              message: 'invalid national insurance number',
            },
          }}
          fullWidth
        />
      </FormSection>
    </>
  );
}
