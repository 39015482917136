import { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Controller, useFormContext } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField, DateFieldProps } from '@mui/x-date-pickers/DateField';

type FieldProps = {
  name: string;
  rules?: {};
  initialValue?: string;
  errorMessage?: string;
} & DateFieldProps<Dayjs>;

export default function DateInput({
  name,
  rules,
  initialValue,
  errorMessage,
  ...otherProps
}: FieldProps) {
  const {
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext();
  const [value, setValue] = useState<Dayjs | null>(
    initialValue ? dayjs(initialValue) : null,
  );

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={value}
      rules={{ ...rules }}
      render={({ field: { onChange, ...restField } }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {
            <DateField
              {...otherProps}
              {...restField}
              value={value}
              onChange={(newValue, context) => {
                if (context.validationError === null) {
                  onChange(newValue);
                  setValue(newValue);
                  if (errors[name]) {
                    clearErrors(name);
                  }

                  // Used for Employed From-Employed To validation
                  if (name.includes('Employed')) {
                    const index = name.substring(name.length - 1);
                    const fieldName = name.substring(8, 10);
                    const otherFieldName = fieldName === 'To' ? 'from' : 'To';
                    if (errors[`Employed${otherFieldName}${index}`])
                      clearErrors(`Employed${otherFieldName}${index}`);
                  }
                }
              }}
              onError={(error) => {
                if (error !== null && error !== 'invalidDate') {
                  setError(name, { type: 'dateError', message: errorMessage });
                }
              }}
              helperText={errors[name]?.message as string}
              format="DD/MM/YYYY"
            />
          }
        </LocalizationProvider>
      )}
    />
  );
}
