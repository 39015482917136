import { Box, LinearProgress, Typography } from '@mui/material';

const SplashModal = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      marginTop="90%"
    >
      <Typography padding="20px 0" variant="h6">
        Preparing your Onboarding...
      </Typography>
      <Box width="254px" textAlign="center" padding="36px 0 36px 0">
        <LinearProgress sx={{ height: '10px', borderRadius: '10px' }} />
      </Box>
    </Box>
  );
};

export default SplashModal;
