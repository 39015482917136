import { useContext, useState } from 'react';
import { useWizard } from 'react-use-wizard';
import { Box, useTheme } from '@mui/material';
import HelpModal from 'onboarding/components/HelpModal';
import HeaderStepper from 'onboarding/components/HeaderStepper';
import HomeIcon from 'onboarding/components/svg-icons/HomeIcon';
import HelpIcon from 'onboarding/components/svg-icons/HelpIcon';
import { OnboardingRequestContext } from 'onboarding/components/AppContainer';

type HeaderProps = {
  name: TStepConfig['name'];
  hideHomeIcon: TStepConfig['hideHomeIcon'];
  description: TStepConfig['description'];
  hideStepper?: TStepConfig['hideStepper'];
  showHelp?: TStepConfig['showHelp'];
  sectionsStatus: TSectionsStatus;
  handleGoHome: Function;
};

const StepHeader = ({
  name,
  hideHomeIcon,
  description,
  hideStepper = false,
  showHelp = false,
  sectionsStatus,
  handleGoHome
}: HeaderProps) => {
  const [isHelpOpen, setIsHelpOpen] = useState(false);
  const { onboardingStepsConfig } = useContext(OnboardingRequestContext);
  const theme = useTheme();
  const {  activeStep } = useWizard();
  const { indexStep = 0 } = onboardingStepsConfig;
  const showStepper = activeStep > indexStep && !hideStepper;

  return (
    <Box
      id="onboarding-header"
      data-testid="onboarding-header"
      display="flex"
      flexDirection="column"
      alignItems="center"
      top="0px"
      marginBottom="14px"
      width="100%"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        padding="0 22px"
        color={theme.branding.textPrimaryColor}
      >
        <Box
          width="24px"
          sx={{ cursor: 'pointer' }}
          onClick={() => handleGoHome()}
        >
          {!hideHomeIcon && <HomeIcon fill={theme.branding.textPrimaryColor} />}
        </Box>
        <Box width="80%" textAlign="center" fontSize="16px" fontWeight="500">
          {name}
        </Box>
        <Box
          id="help-icon"
          data-testid="help-icon"
          width="24px"
          sx={{ cursor: 'pointer' }}
          onClick={() => setIsHelpOpen(true)}
        >
          {showHelp && <HelpIcon fill={theme.branding.textPrimaryColor} />}
        </Box>
      </Box>
      {showStepper && (
        <HeaderStepper stepInfo={description} sectionsStatus={sectionsStatus} />
      )}
      <HelpModal isHelpOpen={isHelpOpen} onClose={() => setIsHelpOpen(false)} />
    </Box>
  );
};

export default StepHeader;
