import { useContext } from 'react';
import { AxiosError } from 'axios';
import { useQuery, useMutation } from 'react-query';
import { AppContext } from 'App';
import Logger from 'util/Logger';
import apiClient from 'api/apiClient';
import apiPrivate from 'onboarding/hooks/useAxiosPrivate';

export function useGetToken() {
  const { organization, requestId, setHasError } = useContext(AppContext);

  const getToken = async () => {
    try {
      const result = await apiClient.get(
        `/OnBoardingRegistration/Auth?orgAlias=${organization}&requestId=${requestId}`,
      );

      setHasError(false);

      if (process.env.NODE_ENV === 'development' && result?.data?.Token) {
        localStorage.removeItem('token'); // remove the old one
        localStorage.setItem('token', JSON.stringify(result.data.Token)); // set the new one coming from the api
      }

      return result.data.Token;
    } catch (err) {
      setHasError(true);
    }
  };

  return getToken;
}

export function useGetSection(sectionName: string, enabled = true) {
  const {
    organization,
    requestId,
    setHasError,
    setValidationStatus,
    setDataErrorMessage,
  } = useContext(AppContext);

  // use the apiPrivate() in order to intercept the calls
  const apiClient = apiPrivate();

  return useQuery(
    sectionName,
    async () => {
      const { data } = await apiClient.get(
        `/CandidateSection?section=${sectionName}&orgAlias=${organization}&requestId=${requestId}`,
      );
      setHasError(false);

      // check if we are making SectionStatuses call
      if (sectionName === 'SectionStatuses') {
        const isOnboardingCompleted = data[0]?.OnboardingCompleted;
        const hasImmutableDataError = data[1]?.IsRequestImmutableDataErrouneous;
        const hasMutableDataError = data[1]?.IsRequestMutableDataErrouneous;
        const isESignCompleted = data[0]?.ESignCompleted;

        const dataErrorMessage = data?.[2]?.Item2;

        // set dataErrorMessage accordingly
        if (dataErrorMessage) {
          const lowerCaseMessage = dataErrorMessage.toLowerCase();

          if (lowerCaseMessage.includes('national insurance number')) {
            setDataErrorMessage({
              type: 'ninError',
              message: dataErrorMessage,
            });
          } else if (lowerCaseMessage.includes('home phone number')) {
            setDataErrorMessage({
              type: 'homePhoneNumberError',
              message: dataErrorMessage,
            });
          } else if (
            lowerCaseMessage.includes('email address already exists')
          ) {
            setDataErrorMessage({
              type: 'emailExistsError',
              message: dataErrorMessage,
            });
          } else if (lowerCaseMessage.includes('bank details')) {
            setDataErrorMessage({
              type: 'bankError',
              message: dataErrorMessage,
            });
          } else {
            setDataErrorMessage({
              type: 'otherError',
              message: dataErrorMessage,
            });
          }
        }

        // Set validationStatus from request statuses
        if (
          isOnboardingCompleted === 'False' &&
          hasImmutableDataError &&
          !hasMutableDataError
        ) {
          setValidationStatus('immutableError');
        } else if (
          isOnboardingCompleted === 'False' &&
          !hasImmutableDataError &&
          hasMutableDataError
        ) {
          setValidationStatus('mutableError');
        } else if (
          isESignCompleted === 'True' &&
          isOnboardingCompleted === 'False' &&
          !hasImmutableDataError &&
          !hasMutableDataError
        ) {
          setValidationStatus('errorSendingDataToPS');
        } else if (
          isOnboardingCompleted === 'True' &&
          !hasImmutableDataError &&
          !hasMutableDataError
        ) {
          setValidationStatus('onboardingCompleted');
        }
      }

      return data;
    },
    {
      retry: 0,
      onError: () => setHasError(true),
      enabled,
    },
  );
}

export function useUpdateSection() {
  const { organization, requestId, setHasError } = useContext(AppContext);

  // use the apiPrivate() in order to intercept the calls
  const apiClient = apiPrivate();

  return useMutation(
    (sectionData: {}) =>
      apiClient.put(
        `/CandidateSection?orgAlias=${organization}&requestId=${requestId}`,
        sectionData,
      ),
    {
      retry: 0,
      onSuccess: () => {
        setHasError(false);
      },
      onError: () => {
        Logger.logEvent(Logger.events.ErrorSubmission);
        setHasError(true);
      },
    },
  );
}

export function useSendVerificationCode() {
  const { organization, requestId, setHasError } = useContext(AppContext);

  return useQuery(
    'send-code',
    async () => {
      const {
        data: { Result: data },
      } = await apiClient.get(
        `/OnBoardingRegistration/OnBoardingOTPRequest?orgAlias=${organization}&requestId=${requestId}`,
      );
      setHasError(false);

      return data;
    },
    {
      retry: 0,
      onError: () => setHasError(true),
    },
  );
}

export function useVerifyCode() {
  const { organization, requestId, setHasError } = useContext(AppContext);

  return useMutation(
    async (code: string) => {
      const { data } = await apiClient.post(
        `/OnBoardingRegistration/OnBoardingOTPVerification?orgAlias=${organization}&requestId=${requestId}&OtpCode=${code}`,
      );
      setHasError(false);

      if (process.env.NODE_ENV === 'development' && data && data.Token) {
        localStorage.setItem('token', JSON.stringify(data.Token));

        apiClient.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${data.Token}`;
      }

      Logger.logEvent(Logger.events.OTPSuccess);

      return data;
    },
    {
      onError: (error: AxiosError) => {
        Logger.logEvent(Logger.events.OTPError);
        if (error.response?.status !== 404) setHasError(true);
      },
    },
  );
}

export function useBrand() {
  const {
    organization,
    requestId,
    setHasError,
    setBrandStatus,
    setIsLinkExpired,
  } = useContext(AppContext);

  return useQuery(
    'get-brand',
    async () => {
      const {
        data: { Result: data },
        status,
      } = await apiClient.get(
        `/OnboardingBrand/GetFromRequest?orgAlias=${organization}&requestId=${requestId}`,
      );
      setHasError(false);

      setBrandStatus(status);

      if (
        data?.[0]?.hasOwnProperty('IsRequestActive') &&
        data?.[0]?.hasOwnProperty('IsRequestCompleted')
      ) {
        const isRequestActive = data[0].IsRequestActive;
        const isRequestCompleted = data[0].IsRequestCompleted;

        if (isRequestCompleted || (!isRequestActive && !isRequestCompleted)) {
          setIsLinkExpired(true);
        } else {
          setIsLinkExpired(false);
        }
      }

      return data;
    },
    {
      retry: 0,
      onError: (err: AxiosError) => {
        setHasError(true);
        setBrandStatus(err?.response?.status!);
      },
    },
  );
}

export function useSigningURL() {
  const { organization, requestId, setHasError } = useContext(AppContext);
  const returnURL = `${window.location.origin}/signing-complete.html`;

  // use the apiPrivate() in order to intercept the calls
  const apiClient = apiPrivate();

  return useQuery(
    'get-signingURL',
    async () => {
      const { data } = await apiClient.get(
        `/OnboardingCandidate/DocuSignURL?orgAlias=${organization}&requestId=${requestId}&returnUrl=${returnURL}`,
      );
      setHasError(false);

      return data;
    },
    {
      retry: 0,
      onError: () => setHasError(true),
    },
  );
}

export function useESignCompleted() {
  const { organization, requestId, setHasError } = useContext(AppContext);

  // use the apiPrivate() in order to intercept the calls
  const apiClient = apiPrivate();

  return useQuery(
    'update-signing-complete',
    async () => {
      const { data } = await apiClient.patch(
        `/OnboardingCandidate/ESignCompleted?orgAlias=${organization}&requestId=${requestId}`,
      );

      Logger.logEvent(Logger.events.DataSentToPS);

      setHasError(!data.IsSuccessful);

      return data;
    },
    {
      retry: 0,
      onError: () => {
        Logger.logEvent(Logger.events.ErrorSendingDataToPS);
        setHasError(true);
      },
    },
  );
}
