type Props = {
  primaryColor: string;
};
const EllipticBackground = ({ primaryColor }: Props) => (
  <svg
    width="375"
    height="224"
    viewBox="0 0 375 224"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_650_1923)">
      <ellipse cx="188" cy="28" rx="258" ry="183" fill={`${primaryColor}`} />
    </g>
    <defs>
      <filter
        id="filter0_d_650_1923"
        x="-82"
        y="-166"
        width="540"
        height="390"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_650_1923"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_650_1923"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default EllipticBackground;
