import { Box } from '@mui/material';
import React from 'react';

const InvalidURL = () => {
  return (
    <Box
      id="index-step-body"
      data-testid="index-step-body"
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        textAlign="center"
        fontSize="18px"
        fontWeight="600"
        width="240px"
        marginTop="12px"
        marginBottom="24px"
        color="#fff"
      >
        Invalid URL
      </Box> 
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        width="90%"
        minHeight="138px"
        fontSize="16px"
        fontWeight="500"
        marginTop="-4px"
        marginBottom="20px"
        paddingTop="12px"
        borderRadius="4px"
        sx={{ backgroundColor: '#FFFFFF' }}
      >
        The URL you are trying to reach is invalid. Please use the link within
        your SMS to complete your onboarding.
      </Box>
    </Box>
  );
};

export default InvalidURL;
