const getContrastRatio = (bgColor: String, fgColor: String) => {
  // Convert the hexadecimal colors to RGB format
  const bgRgb = hexToRgb(bgColor);
  const fgRgb = hexToRgb(fgColor);

  // Calculate the relative luminance of the colors
  const bgLum = getRelativeLuminance(bgRgb);
  const fgLum = getRelativeLuminance(fgRgb);

  // Calculate the contrast ratio between the colors
  const contrastRatio =
    (Math.max(bgLum, fgLum) + 0.05) / (Math.min(bgLum, fgLum) + 0.05);

  return contrastRatio;
};

// Helper function to convert a hexadecimal color to RGB format
const hexToRgb = (hexColor: String) => {
  const r = parseInt(hexColor.substring(0, 2), 16);
  const g = parseInt(hexColor.substring(2, 4), 16);
  const b = parseInt(hexColor.substring(4, 6), 16);

  return { r: r, g: g, b: b };
};

// Helper function to calculate the relative luminance of an RGB color
const getRelativeLuminance = (rgbColor: {
  r: number;
  g: number;
  b: number;
}) => {
  const r = rgbColor.r / 255;
  const g = rgbColor.g / 255;
  const b = rgbColor.b / 255;

  const rLum = r <= 0.03928 ? r / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4);
  const gLum = g <= 0.03928 ? g / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4);
  const bLum = b <= 0.03928 ? b / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4);

  const relativeLuminance = 0.2126 * rLum + 0.7152 * gLum + 0.0722 * bLum;

  return relativeLuminance;
};

export default getContrastRatio;
