const getEnvironment = (environmentName) => {
  // Common environment settings
  let environment = {
    API: {
      baseURL: 'https://ob-ci-api.onbrd.me/',
      // baseURL: 'https://onboarding-api-dev.onbrd.me/',
      // baseURL: 'https://fourthonboardingapi.azurewebsites.net/',
      // baseURL: '/api',
      namespace: '',
    },
    AppInsightsConnectionString: '',
    MixPanelToken: '958ca94fe13b17980172e7c8ac112cad',
  };

  // Production environment specific settings
  if (environmentName === 'production') {
    environment.API.baseURL = '#{API.Onboarding.BaseURL}';
    environment.API.namespace = '#{API.Onboarding.Namespace}';
    environment.AppInsightsConnectionString = '#{AppInsightsConnectionString}';
    environment.MixPanelToken = '#{MixPanelToken}';
  }

  return environment;
};

export default getEnvironment(process.env.NODE_ENV);
