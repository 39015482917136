import { useContext, useEffect, useMemo, useState } from 'react';
import { useWizard } from 'react-use-wizard';
import { Alert, Box, Button, Link, Typography, useTheme } from '@mui/material';
import { AppContext } from 'App';
import Logger from 'util/Logger';
import { useGetSection } from 'api/api';
import CongratulationsIcon from 'onboarding/components/svg-icons/CongratulationsIcon';

let counter = 0;

const OnboardingCompleted = () => {
  const [infoMessageIndex, setInfoMessageIndex] = useState(0);

  const theme = useTheme();
  const { validationStatus, dataErrorMessage } = useContext(AppContext);

  const { data, isFetching, refetch } = useGetSection('SectionStatuses');
  const { goToStep } = useWizard();

  const infoMessages = [
    'Reading the data...',
    'Checking the data...',
    'Validating the data...',
    'Finishing up...',
  ];

  const hasOtherError = useMemo(() => {
    if (
      data?.[0]?.ESignCompleted === 'False' &&
      data?.[0]?.OnboardingCompleted === 'False' &&
      !data?.[1]?.IsRequestImmutableDataErrouneous &&
      !data?.[1]?.IsRequestMutableDataErrouneous
    ) {
      return true;
    }
    return false;
  }, [data]);

  const validationErrorAlert = () => {
    if (hasOtherError) {
      return (
        <Alert sx={{ width: '92%', marginBottom: '12px' }} severity="error">
          Something went wrong. Please contact your hiring manager or go back
          and check your details for any invalid information. <br />
          <Button
            sx={{
              color: '#000',
              padding: '0',
              paddingTop: '5px',
              fontWeight: '600',
            }}
            onClick={() => goToStep(2)}
          >
            Go back
          </Button>
        </Alert>
      );
    }

    if (validationStatus) {
      if (validationStatus === 'errorSendingDataToPS' && counter === 4) {
        Logger.logEvent(Logger.events.ErrorSendingDataToPS);

        return (
          <Alert sx={{ width: '92%', marginBottom: '12px' }} severity="error">
            Oops, it looks like there is a delay in completing your onboarding.
            Please close the app and re-open with the original link in your SMS
            after 5 minutes. If the issue persists, please contact your hiring
            manager.
          </Alert>
        );
      }

      if (validationStatus === 'immutableError') {
        Logger.logEvent(Logger.events.InvalidImmutableData);

        return (
          <Alert sx={{ width: '92%', marginBottom: '12px' }} severity="error">
            Oops, there is an error with your onboarding that needs attention.
            Please contact your hiring manager.
          </Alert>
        );
      }
    }

    if (validationStatus === 'mutableError') {
      Logger.logEvent(Logger.events.InvalidMutableData);

      return (
        <>
          <Alert sx={{ width: '92%', marginBottom: '20px' }} severity="error">
            Oops, there is an error with your onboarding that needs attention.
          </Alert>
          {dataErrorMessage?.type === 'bankError' && (
            <Box sx={{ width: '92%', marginBottom: '20px' }}>
              {dataErrorMessage?.message} Please follow{' '}
              <Link
                onClick={() => goToStep(4)}
                sx={{ color: theme.branding.primaryColor, cursor: 'pointer' }}
              >
                the link
              </Link>{' '}
              to correct the information before continuing with the onboarding
              process to finish.
            </Box>
          )}
          {dataErrorMessage?.type === 'homePhoneNumberError' && (
            <Box sx={{ width: '92%', marginBottom: '20px' }}>
              {dataErrorMessage?.message} Please follow{' '}
              <Link
                onClick={() => goToStep(3)}
                sx={{ color: theme.branding.primaryColor, cursor: 'pointer' }}
              >
                the link
              </Link>{' '}
              to add the information before continuing with the onboarding
              process to finish.
            </Box>
          )}
          {dataErrorMessage?.type === 'ninError' && (
            <Box sx={{ width: '92%', marginBottom: '20px' }}>
              {dataErrorMessage?.message} Please follow{' '}
              <Link
                onClick={() => goToStep(3)}
                sx={{ color: theme.branding.primaryColor, cursor: 'pointer' }}
              >
                the link
              </Link>{' '}
              to correct it. If it is correct, please contact Your hiring
              manager.
            </Box>
          )}
          {(dataErrorMessage?.type === 'emailExistsError' ||
            dataErrorMessage?.type === 'otherError') && (
            <Box sx={{ width: '92%', marginBottom: '20px' }}>
              {dataErrorMessage?.message}
            </Box>
          )}
        </>
      );
    }
  };

  useEffect(() => {
    if (
      validationStatus &&
      validationStatus === 'errorSendingDataToPS' &&
      counter < 4
    ) {
      const timer = setTimeout(() => {
        if (infoMessageIndex < infoMessages.length - 1) {
          setInfoMessageIndex(infoMessageIndex + 1);
        }
        counter += 1;
        refetch();
      }, 5000);

      //Clearing the timeout
      return () => clearTimeout(timer);
    }
  }, [
    infoMessageIndex,
    infoMessages.length,
    isFetching,
    refetch,
    validationStatus,
  ]);

  if (isFetching && !validationStatus) {
    return (
      <Box
        width="100%"
        fontSize="18px"
        fontWeight="500"
        padding="54px 36px 16px 36px"
        textAlign="center"
        color="white"
      >
        <Typography variant="h6">Loading...</Typography>
      </Box>
    );
  } else if (
    validationStatus &&
    validationStatus === 'errorSendingDataToPS' &&
    counter < 4
  ) {
    return (
      <Box
        width="100%"
        fontSize="18px"
        fontWeight="500"
        padding="54px 36px 16px 36px"
        textAlign="center"
        color="white"
      >
        <Typography variant="h6">{infoMessages[infoMessageIndex]}</Typography>
      </Box>
    );
  }

  if (
    (validationStatus && validationStatus !== 'onboardingCompleted') ||
    hasOtherError
  ) {
    return (
      <Box
        id="create-record-step-body"
        data-testid="create-record-step-body"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        borderRadius="4px"
        margin="32px 16px 0 16px"
        width="92%"
        sx={{ backgroundColor: '#FFFFFF' }}
      >
        <Box
          width="100%"
          fontSize="18px"
          fontWeight="500"
          padding="54px 36px 34px 36px"
          textAlign="center"
        >
          <Typography variant="h6">Onboarding unsuccessful!</Typography>
        </Box>
        {validationErrorAlert()}
      </Box>
    );
  }

  Logger.logEvent(Logger.events.OnboardingCompleted);

  return (
    <Box
      id="create-record-step-body"
      data-testid="create-record-step-body"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      borderRadius="4px"
      margin="32px 16px 0 16px"
      width="92%"
      sx={{ backgroundColor: '#FFFFFF' }}
    >
      <Box
        width="100%"
        fontSize="18px"
        fontWeight="500"
        padding="54px 36px 16px 36px"
        textAlign="center"
      >
        <Typography variant="h6">Congratulations!</Typography>
      </Box>
      <Box width="246px" textAlign="center" padding="16px 0">
        <Typography variant="body1">You are successfully onboarded!</Typography>
      </Box>
      <CongratulationsIcon fill={theme.branding.primaryColor} />
      <Box width="254px" textAlign="center" padding="84px 0 136px 0">
        <Typography variant="body1">
          We have sent you an activation email to your inbox. Please follow the
          link to create your employee account and get started!
        </Typography>
      </Box>
    </Box>
  );
};

export default OnboardingCompleted;
