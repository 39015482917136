import environment from 'environment';
import mixpanel from 'mixpanel-browser';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import OnboardingConfig from 'onboarding/onboardingConfig';

/**
 * Implements AppInsights TelemetryInitializer.
 * Filters dependencies (Engage AJAX requests) logged to AppInsights.
 *
 * @param {String} envelope AppInsights `/track` request JSON `data` property
 * @return {Boolean} `false` if request should NOT be logged, otherwise `true`
 */
const dependenciesFilter = (envelope: any): boolean => {
  const dependencyURL = envelope.baseData?.target;
  const blockedURLs = ['mixpanel'];

  if (dependencyURL) {
    const allowedRequest = blockedURLs.every(
      (blockedURL) => !dependencyURL.includes(blockedURL),
    );

    return allowedRequest;
  }

  return true;
};

const logger = (() => {
  let loggerInstance: ReturnType<typeof createLogger>;

  const events = {
    Identify: 'Identify',
    AppStarted: 'App Started',
    OTPSuccess: 'OTP: verify SUCCESS',
    OTPError: 'OTP: verify ERROR',
    ErrorSubmission: 'ERROR: Form submission',
    ErrorSendingDataToPS: 'ERROR: Sending data to PS',
    SentDataToPS: 'Data sent to PS',
    InvalidImmutableData: 'INVALID DATA: Immutable data PS validation failed',
    InvalidMutableData: 'INVALID DATA: Mutable data PS validation failed',
    OnboardingCompleted: 'ONBOARDING COMPLETED',
  } as Record<string, string>;

  OnboardingConfig.steps.forEach((step) => {
    events[step.id] = `${step.name} Page`;
  });

  const createLogger = () => {
    let appInsights: ApplicationInsights | undefined;

    function init() {
      const requestId = new URLSearchParams(window.location.search).get(
        'requestId',
      );
      const orgAlias = new URLSearchParams(window.location.search).get(
        'orgAlias',
      );

      if (environment.MixPanelToken) {
        mixpanel.init(environment.MixPanelToken, {
          debug: process.env.NODE_ENV === 'development',
          track_pageview: false,
          persistence: 'localStorage',
        });
        mixpanel.identify(requestId || undefined);
        mixpanel.register({ organization: orgAlias });
      }

      if (process.env.NODE_ENV !== 'development') {
        const reactPlugin = new ReactPlugin();
        appInsights = new ApplicationInsights({
          config: {
            connectionString: environment.AppInsightsConnectionString,
            enableAutoRouteTracking: true,
            extensions: [reactPlugin],
          },
        });
        appInsights.loadAppInsights();
        appInsights.addTelemetryInitializer(dependenciesFilter);

        if (requestId && orgAlias) {
          appInsights.setAuthenticatedUserContext(requestId, orgAlias);
        }
      }
    }

    const logEvent = (event: string, properties?: Record<string, any>) => {
      if (environment.MixPanelToken) {
        mixpanel.track(event, properties);
      }

      if (appInsights) {
        appInsights.trackEvent({ name: event, properties });
      }
    };

    return {
      events,
      init,
      logEvent,
    };
  };

  return {
    getLogger: function () {
      if (!loggerInstance) {
        loggerInstance = createLogger();
      }
      return loggerInstance;
    },
  };
})();

export default logger.getLogger();
