import { Controller, useFormContext } from 'react-hook-form';
import { TextField, TextFieldProps } from '@mui/material';

type FieldProps = {
  name: string;
  rules?: {};
  defaultValue?: any;
} & TextFieldProps;

export default function Input({
  name,
  rules,
  defaultValue,
  ...otherProps
}: FieldProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{ ...rules }}
      defaultValue={defaultValue || ''}
      render={({ field }) => (
        <TextField
          error={!!errors[name]}
          helperText={errors[name]?.message as string}
          {...field}
          {...otherProps}
        />
      )}
    />
  );
}
