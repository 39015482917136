import VerificationStep from './steps/VerificationStep';
import WelcomeStep from 'onboarding/steps/WelcomeStep';
import DashboardStep from 'onboarding/steps/DashboardStep';
import PersonalDetailsStep from 'onboarding/steps/PersonalDetailsStep';
import EmergencyContactsStep from 'onboarding/steps/EmergencyContactsStep';
import BankDetailsStep from 'onboarding/steps/BankDetailsStep';
import ReferencesStep from 'onboarding/steps/ReferencesStep';
import SensitiveInformationStep from 'onboarding/steps/SensitiveInformationStep';
import EmploymentContractSignStep from './steps/EmploymentContractSignStep';
import RequestPausedStep from './steps/RequestPausedStep';
import ReadyToSign from './steps/ReadyToSignStep';
import CreateEmploymentRecord from './steps/CreateEmploymentRecordStep';
import OnboardingCompleted from './steps/OnboardingCompletedStep';
import PersonalDetailsIcon from './components/svg-icons/PersonalDetailsIcon';
import BankDetailsIcon from './components/svg-icons/BankDetailsIcon';
import EmergencyContactsIcon from './components/svg-icons/EmergencyContactsIcon';
import ReferencesIcon from './components/svg-icons/ReferencesIcon';
import SensitiveInfoIcon from './components/svg-icons/SensitiveInfoIcon';

const OnboardingConfig: TOnboardingConfig = {
  startFromStep: 0,
  indexStep: 2,
  requestPausedStep: 12,
  steps: [
    {
      id: 'Verification',
      name: 'Onboarding Verification',
      stepBody: VerificationStep,
      hideHomeIcon: true,
      hideHeader: false,
      hideFooter: true,
      hashKey: 'verification',
    },
    {
      id: 'Welcome',
      name: 'Welcome',
      buttonLabel: 'Get Started',
      stepBody: WelcomeStep,
      hideHomeIcon: true,
      hideHeader: true,
      hashKey: 'welcome',
    },
    {
      id: 'Dashboard',
      name: 'Dashboard',
      buttonLabel: 'Continue',
      stepBody: DashboardStep,
      hideHomeIcon: true,
      hashKey: 'dashboard',
      showHelp: true,
    },
    {
      id: 'PersonalInformation',
      name: 'Personal Details',
      description: 'Please fill in your personal details',
      stepBody: PersonalDetailsStep,
      icon: PersonalDetailsIcon,
      hashKey: 'personal-details',
      isForm: true,
    },
    {
      id: 'BankAccount',
      name: 'Bank Details',
      description: 'Please fill in your bank details',
      stepBody: BankDetailsStep,
      icon: BankDetailsIcon,
      hashKey: 'bank-details',
      isForm: true,
    },
    {
      id: 'EmergencyContacts',
      name: 'Emergency Contacts',
      description: 'Please fill in your emergency contacts details',
      stepBody: EmergencyContactsStep,
      icon: EmergencyContactsIcon,
      hashKey: 'emergency-contacts',
      isForm: true,
    },
    {
      id: 'References',
      name: 'References',
      description: 'Please fill in references details',
      stepBody: ReferencesStep,
      icon: ReferencesIcon,
      hashKey: 'references',
      isForm: true,
    },
    {
      id: 'SensitiveInformation',
      name: 'Diversity & Inclusion',
      description: 'Please fill in your diversity & inclusion information',
      stepBody: SensitiveInformationStep,
      icon: SensitiveInfoIcon,
      hashKey: 'diversity',
      isForm: true,
    },
    {
      id: 'ReadyToSign',
      name: 'Employment Contract',
      stepBody: ReadyToSign,
      hashKey: 'ready-to-sign',
      hideStepper: true,
    },
    {
      id: 'EmploymentContract',
      name: 'Employment Contract',
      stepBody: EmploymentContractSignStep,
      hashKey: 'sign-contract',
      hideFooter: true,
      hideStepper: true,
    },
    {
      id: 'CreateEmploymentRecord',
      name: 'Create Employment Record',
      stepBody: CreateEmploymentRecord,
      hashKey: 'create-employment-record',
      hideHeader: true,
      hideFooter: true,
    },
    {
      id: 'OnboardingCompleted',
      name: 'Onboarding Completed',
      stepBody: OnboardingCompleted,
      hashKey: 'onboarding-completed',
      hideHeader: true,
      hideFooter: true,
    },
    {
      id: 'RequestPaused',
      name: 'Request Paused',
      stepBody: RequestPausedStep,
      hashKey: 'request-paused',
      hideHeader: true,
      hideFooter: true,
    },
  ],
  findStepIndexById: (stepName: string, steps = []) => {
    let formattedSteps;
    if (steps?.length) {
      formattedSteps = steps;
    } else {
      formattedSteps = OnboardingConfig.steps;
    }
    return formattedSteps.findIndex((step) => step.id === stepName);
  },
};

export default OnboardingConfig;
