const HomeIcon = ({ fill }: TIconProps) => {
  return (
    <svg
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.01562 17.0156V11.0156H12V17.0156H17.0156V9H20.0156L10.0078 0L0 9H3V17.0156H8.01562Z"
        fill={fill}
      />
    </svg>
  );
};

export default HomeIcon;
