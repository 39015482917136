const BankDetailsIcon = ({ fill }: TIconProps) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5 9H2V16H5V9Z" fill={fill} />
      <path d="M11.5 9H8.5V16H11.5V9Z" fill={fill} />
      <path d="M20 18H0V21H20V18Z" fill={fill} />
      <path d="M18 9H15V16H18V9Z" fill={fill} />
      <path d="M10 0L0 5V7H20V5L10 0Z" fill={fill} />
    </svg>
  );
};

export default BankDetailsIcon;
