import { useGetSection } from 'api/api';
import FormSection from 'onboarding/components/form-controls/FormSection';
import Select from 'onboarding/components/form-controls/Select';
import DisabilitySelector from 'onboarding/components/form-controls/DisabilitySelector';

const SensitiveInformationStep = () => {
  const { data, isLoading, isError } = useGetSection('SensitiveInformation');
  const [sensitiveInfo, selectOptions] = data || [[]];

  const getOptions = (name: string): [] => {
    if (data && selectOptions[name] && Array.isArray(selectOptions[name])) {
      return selectOptions[name];
    }

    return [];
  };

  return (
    <FormSection name="Sensitive Information" isReady={!isLoading && !isError}>
      <Select
        id="age"
        data-testid="age"
        name="AgeRange"
        label="What is your age? *"
        defaultValue={sensitiveInfo.AgeRange}
        options={getOptions('AgeRange')}
        rules={{ required: 'required' }}
      />
      <Select
        id="gender-identity"
        data-testid="gender-identity"
        name="GenderIdentity"
        label="How would you describe your gender identity? *"
        defaultValue={sensitiveInfo.GenderIdentity}
        options={getOptions('GenderIdentity')}
        rules={{ required: 'required' }}
      />
      <Select
        id="ethnic-origin"
        data-testid="ethnic-origin"
        name="EthnicOrigin"
        label="How would you describe your ethnicity? *"
        defaultValue={sensitiveInfo.EthnicOrigin}
        options={getOptions('EthnicOrigin')}
        rules={{ required: 'required' }}
      />
      <Select
        id="sexual-orientation"
        data-testid="sexual-orientation"
        name="SexualOrientation"
        label="How would you describe your sexual orientation? *"
        defaultValue={sensitiveInfo.SexualOrientation}
        options={getOptions('SexualOrientation')}
        rules={{ required: 'required' }}
      />
      <Select
        id="religion"
        data-testid="religion"
        name="Religion"
        label="What is your religion? *"
        defaultValue={sensitiveInfo.Religion}
        options={getOptions('Religion')}
        rules={{ required: 'required' }}
      />
      <Select
        id="language"
        data-testid="language"
        name="Language"
        label="What is your primary language? *"
        defaultValue={sensitiveInfo.Language}
        options={getOptions('Language')}
        rules={{ required: 'required' }}
      />
      <DisabilitySelector
        isDisabled={sensitiveInfo.IsDisabled}
        disabilities={sensitiveInfo.Disability || []}
        isDisabledOptions={getOptions('IsDisabled')}
        disabilityOptions={getOptions('Disability')}
      />
    </FormSection>
  );
};

export default SensitiveInformationStep;
