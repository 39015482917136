import environment from 'environment';
import axios from 'axios';

const { baseURL, namespace } = environment.API;

const apiURL = `${baseURL}${namespace}`;

export default axios.create({
  baseURL: apiURL,
  withCredentials: true,
  headers: {
    'Content-type': 'application/json',
  },
});

export const axiosPrivate = axios.create({
  baseURL: apiURL,
  withCredentials: true,
  headers: {
    'Content-type': 'application/json',
  },
})
