import { useState, useEffect, useRef, MouseEvent, useContext } from 'react';
import { useWizard } from 'react-use-wizard';
import { Box, Button, Link, TextField, useTheme } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSendVerificationCode, useVerifyCode } from 'api/api';
import SendCodeTimer from 'onboarding/components/SendCodeTimer';
import { OnboardingRequestContext } from 'onboarding/components/AppContainer';

const VerificationStep = (sectionsStatus: TSectionsStatus) => {
  const [isInputFull, setIsInputFull] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const codeInputRef = useRef<HTMLInputElement>(null);
  const { onboardingStepsConfig } = useContext(OnboardingRequestContext);
  const theme = useTheme();
  const { activeStep, goToStep } = useWizard();
  const {
    data: codeSendData,
    isFetching: isSendingCode,
    refetch: sendCode,
  } = useSendVerificationCode();
  const {
    isLoading: isVerifyingCode,
    isError: isCodeVerificationError,
    error,
    isSuccess: isCodeVerificationSent,
    mutate: verifyCode,
  } = useVerifyCode();

  useEffect(() => {
    if (isCodeVerificationSent && !isCodeVerificationError) {
      let nextStepIndex = activeStep + 1;

      if (
        sectionsStatus &&
        sectionsStatus['PersonalInformation'] === 'True' &&
        sectionsStatus['BankAccount'] === 'True' &&
        sectionsStatus['References'] === 'True' &&
        sectionsStatus['EmergencyContacts'] === 'True' &&
        (sectionsStatus['SensitiveInformation'] === 'True' ||
          !sectionsStatus['SensitiveInformation'])
      ) {
        if (
          sectionsStatus['ESignInProgress'] === 'False' &&
          sectionsStatus['ESignCompleted'] === 'False' &&
          sectionsStatus['OnboardingCompleted'] === 'False'
        ) {
          nextStepIndex = onboardingStepsConfig.steps.findIndex(
            (step) => step.id === 'ReadyToSign',
          );
        }
        if (sectionsStatus['ESignInProgress'] === 'True') {
          nextStepIndex = onboardingStepsConfig.steps.findIndex(
            (step) => step.id === 'EmploymentContract',
          );
        }
        if (
          sectionsStatus['ESignCompleted'] === 'True' &&
          sectionsStatus['OnboardingCompleted'] === 'False'
        ) {
          nextStepIndex = onboardingStepsConfig.steps.findIndex(
            (step) => step.id === 'CreateEmploymentRecord',
          );
        }
        if (sectionsStatus['OnboardingCompleted'] === 'True') {
          nextStepIndex = onboardingStepsConfig.steps.findIndex(
            (step) => step.id === 'OnboardingCompleted',
          );
        }
      }

      goToStep(nextStepIndex);
    }
  }, [
    isCodeVerificationError,
    activeStep,
    goToStep,
    isCodeVerificationSent,
    sectionsStatus,
    onboardingStepsConfig.steps,
    onboardingStepsConfig.requestPausedStep,
  ]);

  const onSendCodeClick = async () => {
    await sendCode();
    setShowTimer(true);
  };

  const onVerifyClick = (event: MouseEvent) => {
    event.preventDefault();
    verifyCode(codeInputRef.current?.value || '');
  };

  return (
    <Box
      id="verification-step-body"
      data-testid="verification-step-body"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      borderRadius="4px"
      margin="0 16px 0 16px"
      textAlign="center"
      sx={{ backgroundColor: '#FFFFFF' }}
    >
      <Box
        id="verification-text"
        data-testid="verification-text"
        color="#333333"
        width="78%"
        lineHeight="20px"
        marginTop="32px"
      >
        {isSendingCode
          ? 'We are sending you a 6 digit passcode via SMS...'
          : `Please enter the 6 digit code we have sent to the number ending in
        xxxxxxx${codeSendData?.To?.substr(
          -3,
          3,
        )} so that you can start your onboarding.`}
      </Box>
      <TextField
        id="verification-code"
        data-testid="verification-code"
        inputRef={codeInputRef}
        margin="normal"
        variant="standard"
        autoFocus
        size="small"
        autoComplete="one-time-code"
        error={isCodeVerificationError}
        helperText={error?.response?.status === 404 && `Invalid Code`}
        onInput={(event) => {
          if ((event.target as HTMLInputElement).value.length === 6) {
            setIsInputFull(true);
          } else {
            setIsInputFull(false);
          }
        }}
        onPaste={(event) => {
          if ((event.target as HTMLInputElement).value.length === 6) {
            setIsInputFull(true);
          } else {
            setIsInputFull(false);
          }
        }}
        onKeyDown={(event) => {
          const ALPHA_NUMERIC_DASH_REGEX = /[0-9]/;

          if (
            !(
              ALPHA_NUMERIC_DASH_REGEX.test(event.key) ||
              ['Backspace', 'ArrowRight', 'ArrowLeft'].includes(event.key) ||
              (event.key === 'v' && event.metaKey)
            )
          ) {
            event.preventDefault();
          }
        }}
        inputProps={{
          inputMode: 'numeric',
          maxLength: 6,
          minLength: 6,
          sx: {
            width: '76px',
            fontSize: '20px',
            textAlign: 'center',
          },
        }}
      />
      <Box marginTop="10px">
        <LoadingButton
          loading={isVerifyingCode}
          disabled={!isInputFull || isSendingCode}
          size="large"
          onClick={onVerifyClick}
          variant="contained"
          disableElevation
          sx={{
            width: '100px',
            textTransform: 'none',
            backgroundColor: theme.branding.primaryColor,
            color: theme.branding.textPrimaryColor,
          }}
        >
          <span>Verify</span>
        </LoadingButton>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        marginTop="20px"
        lineHeight="36px"
      >
        <Button
          disabled={showTimer || isSendingCode}
          onClick={onSendCodeClick}
          sx={{
            textTransform: 'none',
          }}
        >
          Send new code
        </Button>
        {showTimer && (
          <SendCodeTimer
            onExpire={() => setShowTimer(false)}
            expireInSeconds={10}
          />
        )}
      </Box>

      <Box marginTop="16px" marginBottom="12px">
        <img
          id="brand-logo"
          data-testid="brand-logo"
          src={theme.branding.logo}
          alt="Brand logo"
          width="50%"
        />
      </Box>

      <Box marginTop="4px" marginBottom="12px">
        <Link
          sx={{
            fontSize: '15px',
            color: '#333333',
            cursor: 'pointer',
            textDecoration: 'none',
          }}
          className="grey-link"
          href="https://uk.fourth.com/legal/cookies-policy-solutions-and-applications"
          target="_blank"
          rel="noreferrer"
        >
          Cookie Policy
        </Link>
        &nbsp;|&nbsp;
        <Link
          sx={{
            fontSize: '15px',
            color: '#333333',
            cursor: 'pointer',
            textDecoration: 'none',
            hover: '',
          }}
          className="grey-link"
          href="https://uk.fourth.com/legal/privacy-policy"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </Link>
      </Box>
    </Box>
  );
};

export default VerificationStep;
