import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useWizard } from 'react-use-wizard';
import { useForm, FormProvider, FieldErrorsImpl } from 'react-hook-form';
import { useGetSection, useUpdateSection } from 'api/api';
import Logger from 'util/Logger';
import debounce from 'util/debounce';
import { AppContext } from 'App';
import StepHeader from 'onboarding/components/StepHeader';
import StepFooter from 'onboarding/components/StepFooter';
import { OnboardingRequestContext } from 'onboarding/components/AppContainer';

const Step = ({
  name,
  description,
  buttonLabel,
  hideFooter,
  hideHeader,
  hideHomeIcon,
  hideStepper,
  showHelp,
  stepBody,
}: TStepConfig) => {
  const [showWarning, setShowWarning] = useState(false);
  const { organization, requestId, hasError, validationStatus } =
    useContext(AppContext);
  const { onboardingStepsConfig } = useContext(OnboardingRequestContext);
  const { mutateAsync: updateSection } = useUpdateSection();
  const formMethods = useForm({ mode: 'onChange' });
  const { stepCount, activeStep, goToStep } = useWizard();
  const { data } = useGetSection('SectionStatuses', activeStep !== 0);
  const sectionsStatus = data && data[0];
  const [intendedStep, setIntendedStep] = useState<null | number>(null);
  const isFirstRender = useRef(true);

  if (hasError) {
    window.scrollTo({ top: 0 });
  }

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    // Log event on first render only
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    Logger.logEvent(Logger.events[onboardingStepsConfig.steps[activeStep].id]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (Object.keys(formMethods.formState.errors).length) {
        const message = 'Changes that you made may not be saved.';
        event.returnValue = message; // Standard for most browsers
        return message; // For some older browsers
      }
    };

    // Attach the event handler when the component mounts
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Detach the event handler when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [formMethods.formState.errors]);

  useEffect(() => {
    // Handle pop state(back or forward navigation in the browser)
    const handlePopstate = async () => {
      //take the hash description without the #
      const hash = window.location.hash.substring(1);

      // if hash exists and intendedStep is null find the index and set it to intendedStep
      if (hash && !intendedStep) {
        const step = onboardingStepsConfig.steps.findIndex(
          (step) => step.hashKey === hash,
        );
        await setIntendedStep(step);
      }

      // Handle back or forward navigation
      // Check if formMethods.formState.errors object is empty and apply logic
      if (Object.keys(formMethods.formState.errors).length) {
        if (!showWarning) {
          // We use react-use-wizard's goToStep to the activeStep
          // in order to prevent the browser from navigating
          // (mimicking, since it still navigates but to the current step)
          setShowWarning(true);
          goToStep(activeStep);
        }
      }
    };

    // Add the event listener when the component mounts
    window.addEventListener('popstate', handlePopstate);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, [
    formMethods.formState.errors,
    activeStep,
    showWarning,
    goToStep,
    onboardingStepsConfig.steps,
    intendedStep,
  ]);

  // If we have immutableError redirect the user to last screen
  useEffect(() => {
    if (
      validationStatus &&
      validationStatus === 'immutableError' &&
      activeStep > 1
    ) {
      goToStep(
        onboardingStepsConfig.findStepIndexById(
          'OnboardingCompleted',
          onboardingStepsConfig?.steps,
        ),
      );
    }
  }, [activeStep, goToStep, onboardingStepsConfig, validationStatus]);

  const handleGoHome = () => {
    const { indexStep = 0 } = onboardingStepsConfig;
    if (Object.keys(formMethods.formState.errors).length) {
      setShowWarning(true);
      setIntendedStep(indexStep);
    } else {
      goToStep(indexStep);
    }
  };

  // nextStep() from react-use-wizard is not working due to an issue with the stored active step number in it
  // This is an alternative implementation
  const goToNextStep = () => {
    const getNextStep = () => {
      if (activeStep === onboardingStepsConfig.indexStep) {
        return onboardingStepsConfig.steps.findIndex(
          (step) => step.id === 'ReadyToSign',
        );
      }

      if (activeStep === stepCount - 1) {
        return onboardingStepsConfig.indexStep || 0; // Dashboard step
      }

      return activeStep + 1; // next step in OnboardingConfig.steps
    };

    goToStep(getNextStep());
  };

  const prepareSubmitPayload = useCallback(
    (formData: {}, errors: { [key: string]: any }) => {
      const sectionName = onboardingStepsConfig.steps[activeStep].id;
      const submitPayload = {
        OrgAlias: organization,
        RequestId: requestId,
        Section: sectionName,
      } as { [key: string]: any };

      Object.entries(formData).forEach((entry) => {
        let [formField, value] = entry;
        const isValueInvalid = errors && errors[formField];

        if (typeof value === 'object' && dayjs(value as Date).isValid()) {
          value = dayjs(value as Date).format('YYYY-MM-DD');
        }

        if (
          sectionName === 'EmergencyContacts' ||
          sectionName === 'References'
        ) {
          const sectionNameForUpdate =
            sectionName.slice(0, sectionName.length - 1) + formField.slice(-1); // `EmergencyContacts` becomes `EmergencyContact1` or `EmergencyContact2`
          const sectionPropName = formField.slice(0, formField.length - 1); // `Title1` becomes `Title`

          submitPayload[`${sectionNameForUpdate}.${sectionPropName}`] =
            isValueInvalid ? null : value;
        } else {
          submitPayload[`${sectionName}.${formField}`] = isValueInvalid
            ? null
            : value;
        }
      });

      return submitPayload;
    },
    [activeStep, onboardingStepsConfig.steps, organization, requestId],
  );

  const onChange = useCallback(() => {
    if (onboardingStepsConfig.steps[activeStep].isForm) {
      const formData = formMethods.getValues();
      const submitPayload = prepareSubmitPayload(
        formData,
        formMethods.formState.errors,
      );

      updateSection(submitPayload);
    }
  }, [
    activeStep,
    formMethods,
    onboardingStepsConfig.steps,
    prepareSubmitPayload,
    updateSection,
  ]);

  const debouncedOnChange = debounce(onChange, 3000);

  useEffect(() => {
    const subscription = formMethods.watch(() => debouncedOnChange());
    return () => subscription.unsubscribe();
  }, [debouncedOnChange, formMethods]);

  const onSubmit = async (data: {}) => {
    if (activeStep === 0) return;
    if (onboardingStepsConfig.steps[activeStep].isForm) {
      const sectionData = prepareSubmitPayload(data, []);
      const result = await updateSection(sectionData);

      if (result.status === 200) goToNextStep();
    } else {
      goToNextStep();
    }
  };

  const disableFooterButton =
    sectionsStatus &&
    onboardingStepsConfig.steps[activeStep].id === 'ReadyToSign' &&
    onboardingStepsConfig.steps.some(
      (configStep) =>
        configStep.isForm && sectionsStatus[configStep.id] === 'False',
    );

  const onSubmitError = (
    errors: Partial<FieldErrorsImpl<{ [x: string]: any }>>,
  ) => {
    setShowWarning(true);
  };

  const handleLeave = () => {
    setShowWarning(false);
    if (intendedStep) {
      goToStep(intendedStep);
      setIntendedStep(null);
    } else {
      goToNextStep();
    }
  };

  const handleStay = () => {
    setShowWarning(false);
    setIntendedStep(null);
  };

  return (
    <Box
      id="onboarding-step"
      data-testid="onboarding-step"
      display="flex"
      flexDirection="column"
      alignItems="center"
      overflow="hidden"
      minHeight="100%"
    >
      {!hideHeader && (
        <StepHeader
          name={name}
          hideHomeIcon={hideHomeIcon}
          description={description}
          hideStepper={hideStepper}
          showHelp={showHelp}
          sectionsStatus={sectionsStatus}
          handleGoHome={handleGoHome}
        />
      )}
      {hasError && activeStep !== 0 && (
        <Alert sx={{ width: '92%', marginBottom: '10px' }} severity="error">
          Something went wrong. Please contact your hiring manager.
        </Alert>
      )}
      <Box
        id="step-body"
        data-testid="step-body"
        flex="1"
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
        width="100%"
      >
        <FormProvider {...formMethods}>
          <form
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              flex: 1,
            }}
            // onSubmit={formMethods.handleSubmit(onSubmit)}
            onSubmit={formMethods.handleSubmit(onSubmit, onSubmitError)}
            // onChange={debouncedOnChange}
          >
            {stepBody(sectionsStatus)}
            {!hideFooter && (
              <StepFooter
                buttonLabel={buttonLabel}
                buttonDisabled={disableFooterButton}
              />
            )}
          </form>
        </FormProvider>
      </Box>
      <Dialog
        open={showWarning}
        onClose={handleStay}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Warning</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            If you leave the page, all invalid fields will not be saved.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLeave}>Leave anyway</Button>
          <Button onClick={handleStay} autoFocus>
            Stay
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Step;
