import { useContext } from 'react';
import { useWizard } from 'react-use-wizard';
import { Box, Step, StepLabel, Stepper, useTheme } from '@mui/material';
import { OnboardingRequestContext } from 'onboarding/components/AppContainer';

type StepperProps = {
  stepInfo?: string;
  sectionsStatus: TSectionsStatus;
};

const HeaderStepper = ({
  stepInfo,
  sectionsStatus: sections,
}: StepperProps) => {
  const { onboardingStepsConfig } = useContext(OnboardingRequestContext);
  const theme = useTheme();
  const { activeStep } = useWizard();
  const { indexStep = 0 } = onboardingStepsConfig;
  const formSteps = onboardingStepsConfig.steps.filter((step) => step.isForm);
  const stepperActiveStep = activeStep - indexStep - 1;
  const stepperSteps =
    sections &&
    formSteps.map((step, i) => {
      return (
        <Step
          key={step.id}
          completed={i !== stepperActiveStep && sections[step.id] === 'True'}
          active={i === stepperActiveStep}
          sx={{
            '& .MuiStepLabel-root .Mui-active': {
              color: theme.branding.primaryColor,
            },
            '& .MuiStepLabel-root .Mui-completed': {
              color: theme.branding.primaryColor,
            },
            '& .MuiStepIcon-text': {
              fill: theme.branding.textPrimaryColor,
            },
          }}
        >
          <StepLabel></StepLabel>
        </Step>
      );
    });

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="90%"
      height="138px"
      marginTop="4%"
      color="#333333"
      borderRadius="4px"
      padding="18px 0 18px 0"
      sx={{ backgroundColor: '#FFFFFF' }}
    >
      <Box
        textAlign="center"
        fontSize="14px"
        fontWeight="500"
        width="240px"
        marginTop="12px"
        marginBottom="24px"
      >
        {stepInfo}
      </Box>
      <Stepper>{stepperSteps}</Stepper>
    </Box>
  );
};

export default HeaderStepper;
