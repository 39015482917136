import { Typography } from '@mui/material';

type InputProps = {
  id?: string;
  'data-testid'?: string;
  label: string;
  value: string;
};

const ReadOnlyInput = ({ label, value }: InputProps) => {
  return (
    <>
      <Typography variant="caption">{label}</Typography>
      <Typography variant="body2">{value}</Typography>
    </>
  );
};

export default ReadOnlyInput;
