const FormsCompleteIcon = ({ fill }: TIconProps) => {
  return (
    <svg
      width="174"
      height="154"
      viewBox="0 0 174 154"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_794_8274)">
        <path
          d="M151.194 92.2556H4.76652C3.58542 92.2543 2.45308 91.7834 1.61792 90.9463C0.782759 90.1092 0.312979 88.9742 0.311646 87.7904V5.034H155.649V87.7904C155.648 88.9742 155.178 90.1092 154.343 90.9463C153.508 91.7834 152.375 92.2543 151.194 92.2556Z"
          fill="#E6E6E6"
        />
        <path
          d="M145.367 83.7084H10.5938C9.98532 83.7077 9.40199 83.4652 8.97176 83.0339C8.54152 82.6027 8.29951 82.018 8.29883 81.4081V15.8812C8.29951 15.2714 8.54152 14.6867 8.97176 14.2554C9.40199 13.8242 9.98532 13.5816 10.5938 13.5809H145.367C145.975 13.5816 146.559 13.8242 146.989 14.2554C147.419 14.6867 147.661 15.2714 147.662 15.8812V81.4081C147.661 82.018 147.419 82.6027 146.989 83.0339C146.559 83.4652 145.975 83.7077 145.367 83.7084Z"
          fill="white"
        />
        <path
          d="M155.961 8.05036H0V4.46527C0.00133416 3.28142 0.471114 2.14643 1.30628 1.30932C2.14144 0.47221 3.27378 0.00133347 4.45487 -3.8147e-06H151.506C152.687 0.00133347 153.819 0.47221 154.655 1.30932C155.49 2.14643 155.959 3.28142 155.961 4.46527V8.05036Z"
          fill={fill}
        />
        <path
          d="M42.4728 58.6193H18.4852C17.8768 58.6186 17.2935 58.376 16.8632 57.9448C16.433 57.5135 16.191 56.9288 16.1903 56.319V40.5128C16.191 39.9029 16.433 39.3182 16.8632 38.887C17.2935 38.4558 17.8768 38.2132 18.4852 38.2125H42.4728C43.0813 38.2132 43.6646 38.4557 44.0948 38.887C44.5251 39.3182 44.7671 39.9029 44.7678 40.5128V56.319C44.7671 56.9288 44.5251 57.5135 44.0948 57.9448C43.6646 58.376 43.0813 58.6186 42.4728 58.6193Z"
          fill="#E6E6E6"
        />
        <path
          d="M138.494 40.7714H62.7031C62.3646 40.7714 62.0399 40.6366 61.8005 40.3966C61.5611 40.1567 61.4266 39.8313 61.4266 39.4919C61.4266 39.1526 61.5611 38.8272 61.8005 38.5872C62.0399 38.3473 62.3646 38.2125 62.7031 38.2125H138.494C138.832 38.2125 139.157 38.3473 139.397 38.5872C139.636 38.8272 139.77 39.1526 139.77 39.4919C139.77 39.8313 139.636 40.1567 139.397 40.3966C139.157 40.6366 138.832 40.7714 138.494 40.7714Z"
          fill="#E6E6E6"
        />
        <path
          d="M138.494 49.9241H62.7032C62.3646 49.9241 62.04 49.7893 61.8006 49.5493C61.5612 49.3094 61.4268 48.984 61.4268 48.6447C61.4268 48.3054 61.5612 47.98 61.8006 47.74C62.04 47.5001 62.3646 47.3653 62.7032 47.3653H138.494C138.833 47.3653 139.157 47.5001 139.397 47.74C139.636 47.98 139.77 48.3054 139.77 48.6447C139.77 48.984 139.636 49.3094 139.397 49.5493C139.157 49.7893 138.833 49.9241 138.494 49.9241Z"
          fill="#E6E6E6"
        />
        <path
          d="M138.494 59.0769H62.7031C62.3646 59.0769 62.0399 58.9421 61.8005 58.7021C61.5611 58.4622 61.4266 58.1368 61.4266 57.7974C61.4266 57.4581 61.5611 57.1327 61.8005 56.8927C62.0399 56.6528 62.3646 56.518 62.7031 56.518H138.494C138.832 56.518 139.157 56.6528 139.397 56.8927C139.636 57.1327 139.77 57.4581 139.77 57.7974C139.77 58.1368 139.636 58.4622 139.397 58.7021C139.157 58.9421 138.832 59.0769 138.494 59.0769Z"
          fill="#E6E6E6"
        />
        <path
          d="M113.275 150.55H109.965L108.39 137.753H113.275L113.275 150.55Z"
          fill="#A0616A"
        />
        <path
          d="M107.6 149.602H113.984V153.631H103.581C103.581 153.102 103.685 152.578 103.887 152.089C104.089 151.6 104.385 151.156 104.758 150.782C105.131 150.408 105.574 150.111 106.062 149.909C106.55 149.706 107.072 149.602 107.6 149.602Z"
          fill="#2F2E41"
        />
        <path
          d="M127.044 150.55H123.734L122.16 137.753H127.045L127.044 150.55Z"
          fill="#A0616A"
        />
        <path
          d="M121.37 149.602H127.754V153.631H117.351C117.351 153.102 117.455 152.578 117.657 152.089C117.859 151.6 118.155 151.156 118.528 150.782C118.901 150.408 119.344 150.111 119.832 149.909C120.319 149.706 120.842 149.602 121.37 149.602Z"
          fill="#2F2E41"
        />
        <path
          d="M126.623 147.609H123.253C122.949 147.607 122.656 147.492 122.432 147.285C122.208 147.079 122.069 146.796 122.043 146.492L119.187 112.015C119.179 111.915 119.134 111.821 119.06 111.753C118.987 111.684 118.891 111.645 118.791 111.643C118.69 111.641 118.593 111.677 118.517 111.743C118.442 111.809 118.393 111.901 118.382 112.001L114.281 146.535C114.245 146.831 114.103 147.104 113.881 147.302C113.658 147.5 113.371 147.609 113.074 147.609H108.937C108.616 147.609 108.308 147.481 108.081 147.254C107.853 147.027 107.724 146.72 107.722 146.398C107.713 144.229 107.698 142.044 107.684 139.858C107.552 120.395 107.417 100.268 111.471 92.3836L111.508 92.3117L126.14 92.0609L126.177 92.1415C130.603 101.838 129.26 123.5 127.96 144.448L127.835 146.467C127.816 146.776 127.679 147.066 127.454 147.278C127.229 147.49 126.932 147.609 126.623 147.609Z"
          fill="#2F2E41"
        />
        <path
          d="M119.691 66.1469C123.354 66.1469 126.323 63.171 126.323 59.5001C126.323 55.8292 123.354 52.8533 119.691 52.8533C116.029 52.8533 113.06 55.8292 113.06 59.5001C113.06 63.171 116.029 66.1469 119.691 66.1469Z"
          fill="#A0616A"
        />
        <path
          d="M110.65 93.5335L110.833 85.9127L109.518 79.9667C109.185 78.4943 109.205 76.9641 109.574 75.5007C109.944 74.0373 110.653 72.6821 111.644 71.5454C112.608 70.4144 113.825 69.5279 115.196 68.9581C116.567 68.3883 118.053 68.1514 119.533 68.2668H119.533C119.629 68.2742 119.724 68.2822 119.82 68.2908C121.085 68.4049 122.313 68.773 123.432 69.3731C124.552 69.9732 125.539 70.793 126.336 71.7838C127.149 72.7801 127.753 73.9303 128.114 75.1659C128.474 76.4014 128.582 77.697 128.432 78.9754L126.84 92.9169L110.65 93.5335Z"
          fill={fill}
        />
        <path
          d="M105.017 107.192C105.33 106.947 105.586 106.636 105.766 106.282C105.946 105.927 106.046 105.538 106.06 105.14C106.074 104.742 106 104.347 105.845 103.98C105.689 103.614 105.455 103.287 105.16 103.021L108.893 94.1041L103.966 95.0317L101.176 103.357C100.763 103.885 100.558 104.545 100.599 105.213C100.64 105.882 100.924 106.512 101.398 106.985C101.871 107.458 102.501 107.74 103.168 107.778C103.836 107.816 104.493 107.607 105.017 107.192Z"
          fill="#A0616A"
        />
        <path
          d="M133.189 108.261C132.891 107.998 132.654 107.674 132.494 107.309C132.334 106.945 132.256 106.55 132.265 106.153C132.274 105.755 132.37 105.364 132.546 105.007C132.722 104.651 132.974 104.337 133.284 104.089L130.063 94.9731L134.93 96.1803L137.242 104.652C137.624 105.201 137.792 105.872 137.713 106.537C137.634 107.203 137.315 107.816 136.815 108.261C136.316 108.705 135.671 108.951 135.002 108.951C134.334 108.951 133.689 108.705 133.189 108.261Z"
          fill="#A0616A"
        />
        <path
          d="M105.61 102.822C105.439 102.822 105.27 102.786 105.114 102.716L102.273 101.44C102 101.318 101.781 101.099 101.659 100.825C101.538 100.551 101.521 100.241 101.613 99.956L106.378 85.1207L108.219 73.944C108.285 73.5469 108.429 73.167 108.644 72.827C108.859 72.487 109.14 72.1938 109.47 71.9648C109.801 71.7358 110.173 71.5758 110.567 71.4942C110.96 71.4125 111.365 71.4111 111.759 71.4898C112.153 71.5685 112.527 71.7258 112.859 71.9523C113.191 72.1788 113.474 72.47 113.691 72.8084C113.909 73.1468 114.056 73.5256 114.124 73.9223C114.193 74.3189 114.181 74.7252 114.089 75.1172L111.291 87.1036L106.772 101.96C106.697 102.209 106.543 102.428 106.333 102.583C106.124 102.739 105.871 102.822 105.61 102.822V102.822Z"
          fill={fill}
        />
        <path
          d="M132.707 102.822C132.447 102.822 132.193 102.739 131.984 102.583C131.775 102.428 131.621 102.209 131.545 101.96L127.029 87.112L124.228 75.1172C124.137 74.7252 124.125 74.3189 124.193 73.9222C124.261 73.5256 124.409 73.1468 124.626 72.8084C124.843 72.4699 125.126 72.1788 125.458 71.9522C125.79 71.7257 126.164 71.5684 126.558 71.4897C126.952 71.411 127.358 71.4125 127.751 71.4941C128.144 71.5757 128.517 71.7357 128.847 71.9647C129.177 72.1937 129.458 72.4869 129.673 72.827C129.888 73.167 130.033 73.5469 130.098 73.944L131.944 85.1402L136.704 99.956C136.796 100.241 136.78 100.551 136.658 100.825C136.536 101.099 136.318 101.318 136.044 101.44L133.204 102.716C133.048 102.786 132.878 102.822 132.707 102.822Z"
          fill={fill}
        />
        <path
          d="M119.828 52.1529C117.985 51.3527 115.577 52.3484 114.833 54.2184C114.37 55.3822 114.512 56.6949 114.767 57.9214C115.022 59.1479 115.386 60.3718 115.347 61.6241C115.308 62.8764 114.77 64.2055 113.661 64.7848C112.999 65.131 112.224 65.1695 111.507 65.3759C110.789 65.5824 110.048 66.0651 109.944 66.8065C109.788 67.9281 111.145 68.7824 111.136 69.9148C111.127 71.1147 109.631 71.8273 109.437 73.0114C109.403 73.3581 109.458 73.7076 109.597 74.0271C109.735 74.3467 109.952 74.6257 110.227 74.8381C110.783 75.2563 111.414 75.5626 112.086 75.74C117.256 77.3963 122.761 77.7107 128.085 76.6537C129.143 76.4422 130.231 76.1562 131.053 75.456C131.875 74.7558 132.345 73.5301 131.873 72.5581C131.165 71.1008 128.916 71.0178 128.132 69.5999C127.662 68.7494 127.878 67.7052 127.977 66.738C128.233 64.24 128.823 61.3217 128.203 58.8885C127.644 56.6902 126.43 53.8494 124.694 52.3936C123.448 51.3489 121.07 51.8249 119.478 52.1472L119.828 52.1529Z"
          fill="#2F2E41"
        />
        <path
          d="M173.73 154H79.2328C79.1612 154 79.0925 153.971 79.0419 153.921C78.9912 153.87 78.9628 153.801 78.9628 153.729C78.9628 153.658 78.9912 153.589 79.0419 153.538C79.0925 153.487 79.1612 153.459 79.2328 153.459H173.73C173.802 153.459 173.87 153.487 173.921 153.538C173.972 153.589 174 153.658 174 153.729C174 153.801 173.972 153.87 173.921 153.921C173.87 153.971 173.802 154 173.73 154Z"
          fill="#CCCCCC"
        />
        <path
          d="M163.78 147.838C159.887 153.063 151.805 153.614 151.805 153.614C151.805 153.614 150.036 145.691 153.929 140.465C157.822 135.239 165.904 134.689 165.904 134.689C165.904 134.689 167.673 142.612 163.78 147.838Z"
          fill="#E6E6E6"
        />
        <path
          d="M143.947 149.802C146.516 153.251 151.849 153.614 151.849 153.614C151.849 153.614 153.016 148.385 150.447 144.937C147.878 141.488 142.546 141.125 142.546 141.125C142.546 141.125 141.378 146.354 143.947 149.802Z"
          fill="#E6E6E6"
        />
      </g>
      <defs>
        <clipPath id="clip0_794_8274">
          <rect width="174" height="154" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FormsCompleteIcon;
