import { useContext } from 'react';
import {
  Box,
  Chip,
  LinearProgress,
  Skeleton,
  Typography,
  linearProgressClasses,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useWizard } from 'react-use-wizard';
import dayjs from 'dayjs';
import { AppContext } from 'App';
import { OnboardingRequestContext } from 'onboarding/components/AppContainer';
import { useGetSection } from 'api/api';

const DashboardStep = () => {
  const { onboardingStepsConfig, sensitiveInfoStepVisible } = useContext(
    OnboardingRequestContext,
  );
  const { setIsCompleted } = useContext(AppContext);
  const { data, isLoading, isError } = useGetSection('Dashboard');
  const theme = useTheme();
  const { goToStep } = useWizard();
  const [[personalDetails, stepsStatus]] = data || [[{}], {}];

  if (
    stepsStatus &&
    stepsStatus['SensitiveInformation'] &&
    !sensitiveInfoStepVisible
  ) {
    delete stepsStatus['SensitiveInformation'];
  }

  const calcProgress = () => {
    if (data) {
      const numberCompletedFormSteps = Object.entries(stepsStatus).filter(
        (entry) =>
          onboardingStepsConfig.steps.find((step) => step.id === entry[0])
            ?.isForm && entry[1] === 'True',
      ).length;
      const numberAllFormSteps = Object.keys(stepsStatus).filter(
        (stepName) =>
          onboardingStepsConfig.steps.find((step) => step.id === stepName)
            ?.isForm,
      ).length;

      return Math.round((numberCompletedFormSteps / numberAllFormSteps) * 100);
    }
    return 0;
  };

  const progress = calcProgress();
  setIsCompleted(progress === 100);

  let daysUntilStart = 0;

  if (data) {
    const calcRemainingDays = dayjs(personalDetails?.StartDate).diff(
      dayjs(),
      'day',
    );
    if (calcRemainingDays > 0) {
      daysUntilStart = calcRemainingDays;
    }
  }

  const button = (
    name: TStepConfig['name'],
    stepIndex: number,
    icon: TStepConfig['icon'],
    isCompleted = false,
  ) => (
    <Box
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      width="90%"
      height="72px"
      marginBottom="8px"
      paddingLeft="14px"
      borderRadius="4px"
      sx={{
        backgroundColor: '#FFFFFF',
        filter: 'drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.15))',
        cursor: 'pointer',
      }}
      id="step-button"
      data-testid="step-button"
      key={stepIndex}
      onClick={() => goToStep(stepIndex)}
    >
      {icon &&
        icon({ fill: isCompleted ? theme.branding.primaryColor : '#B0B0B0' })}
      <Box paddingLeft="16px" width="95%">
        {name}
      </Box>
      <Box
        width="8px"
        height="100%"
        borderRadius="0 4px 4px 0"
        sx={{
          backgroundColor: isCompleted
            ? theme.branding.primaryColor
            : '#B0B0B0',
        }}
      />
    </Box>
  );
  const buttons =
    !isLoading &&
    !isError &&
    onboardingStepsConfig.steps.map((step, i) => {
      if (step.isForm) {
        return button(step.name, i, step.icon, stepsStatus[step.id] === 'True');
      }
      return null;
    });
  const skeletons = (
    <Box display="flex" flexDirection="column" marginBottom="15px">
      <Skeleton variant="text" sx={{ fontSize: '16px', width: '150px' }} />
      <Skeleton variant="text" sx={{ fontSize: '14px', width: '150px' }} />
      <Skeleton variant="text" sx={{ fontSize: '12px', width: '150px' }} />
      <Skeleton variant="text" sx={{ fontSize: '12px', width: '150px' }} />
    </Box>
  );
  const userDetails = !isLoading && (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      marginBottom="24px"
    >
      <Box fontSize="16px" fontWeight="500" lineHeight="24px">
        {personalDetails?.FirstName} {personalDetails?.SurName}
      </Box>
      <Box fontSize="14px" fontWeight="400" lineHeight="24px">
        {personalDetails?.JobTitle} at {personalDetails?.Location}
      </Box>
      <Chip
        label={`${daysUntilStart} days until you start`}
        sx={{ mt: '4px' }}
      />
    </Box>
  );

  return (
    <Box
      id="index-step-body"
      data-testid="index-step-body"
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        id="dashboard-header"
        data-testid="dashboard-header"
        textAlign="center"
        width="90%"
        minHeight="138px"
        fontSize="14px"
        fontWeight="500"
        marginTop="-4px"
        marginBottom="20px"
        paddingTop="12px"
        borderRadius="4px"
        sx={{ backgroundColor: '#FFFFFF' }}
      >
        <Box>Please fill out each section to complete your Onboarding.</Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '20px',
            marginBottom: '20px',
          }}
        >
          <Box sx={{ minWidth: 35, mb: '4px' }}>
            <Typography
              variant="body2"
              fontWeight="700"
              color="text.secondary"
            >{`${progress}%`}</Typography>
          </Box>
          <Box sx={{ width: '90%', ml: 5, mr: 5 }}>
            <LinearProgress
              variant="determinate"
              value={progress}
              sx={{
                height: '10px',
                borderRadius: '10px',
                [`& .${linearProgressClasses.bar}`]: {
                  borderRadius: 5,
                  backgroundColor: theme.branding.primaryColor,
                },
              }}
            />
          </Box>
        </Box>
      </Box>
      {isLoading || isError ? skeletons : userDetails}
      {buttons}
    </Box>
  );
};

export default DashboardStep;
