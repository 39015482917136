import { Timeout } from 'react-number-format/types/types';

const debounce = (fn: () => void, delay: number) => {
  let timer: Timeout;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => fn(), delay);
  };
};

export default debounce;
