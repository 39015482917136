import { useContext } from 'react';
import Container from '@mui/material/Container';
import { Wizard } from 'react-use-wizard';
import { OnboardingRequestContext } from 'onboarding/components/AppContainer';
import Step from 'onboarding/components/Step';

import { AppContext } from 'App';
import InvalidURL from './components/InvalidURL';
import ExpiredLink from './components/ExpiredLink';
import RequestPausedStep from './steps/RequestPausedStep';

const RenderApp = () => {
  const { brandStatus, isLinkExpired } = useContext(AppContext);
  const { onboardingStepsConfig, isRequestPaused } = useContext(
    OnboardingRequestContext,
  );
  const onboardingSteps = onboardingStepsConfig.steps.map((stepProps, i) => (
    <Step key={i} {...stepProps} />
  ));

  const searchParams = new URLSearchParams(window.location.search);
  const organization = searchParams.get('orgAlias') || '';
  const requestId = searchParams.get('requestId') || '';

  if (!organization || !requestId || brandStatus === 404) {
    return <InvalidURL />;
  }

  if (isLinkExpired) {
    return <ExpiredLink />;
  }

  if (isRequestPaused) {
    return <RequestPausedStep />;
  }

  return (
    <Wizard startIndex={onboardingStepsConfig.startFromStep} hashEnabled={true}>
      {onboardingSteps}
    </Wizard>
  );
};

const Onboarding = () => {
  return (
    <Container
      id="onboarding-container"
      data-testid="onboarding-container"
      disableGutters
      fixed
      maxWidth="xs"
      sx={{ position: 'absolute', height: '100%', paddingTop: '24px' }}
    >
      <RenderApp />
    </Container>
  );
};

export default Onboarding;
